import AbstractModel from 'base/AbstractModel';
import { Nullable } from 'base/types/BaseTypes';

import { IResponseOrder } from '../types/OrderTypes';

export class OrderCalcModel extends AbstractModel {
  order: IResponseOrder[] = [];
  regionDiscount: Nullable<number> = null;
  userDiscount: Nullable<number> = null;
  serviceDiscount: Nullable<number> = null;
  totalSumGoods: Nullable<number> = null;
  totalVolumeGoods: Nullable<number> = null;
  totalWeightGoods: Nullable<number> = null;
  totalSumGoodsWithDiscount: Nullable<number> = null;
  totalSumGoodsDiscount: Nullable<number> = null;
  totalSumServices: Nullable<number> = null;
  totalSumServicesWithDiscount: Nullable<number> = null;
  totalSumServicesDiscount: Nullable<number> = null;
  totalArea: Nullable<number> = null;
  totalSum: Nullable<number> = null;
  totalSumWithDiscount: Nullable<number> = null;
  documentPath: Nullable<string> = null;
  pdfWebPath: Nullable<string> = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

import AbstractModel from 'base/AbstractModel';
import { Nullable } from 'base/types/BaseTypes';

export class LocationModel extends AbstractModel {
  id: Nullable<number> = null;
  title: Nullable<string> = null;
  discount: Nullable<{ discount: Nullable<number> }> = null;
  bold: boolean = false;
  order: number = 10;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

import { Box } from '@material-ui/core';

import { System } from 'modules/systems/models/System';

import TagItem from './TagItem';

import { ReactComponent as airNoise } from 'assets/icons/air-noise.svg';
import { ReactComponent as impactNoise } from 'assets/icons/impact-noise.svg';
import { ReactComponent as soundproofing } from 'assets/icons/soundproofing.svg';
import { ReactComponent as systemThickness } from 'assets/icons/system-thickness.svg';

interface IIndicatorsProps {
  system: System;
}

const Indicators: React.FC<IIndicatorsProps> = ({ system }) => {
  const { indicatorThickness, indicatorRW, indicatorDeltaRw, indicatorDeltaLNW } = system;

  return (
    <Box mb={3}>
      {indicatorThickness !== null && indicatorThickness.toString() !== '0' && (
        <Box mb={1.5}>
          <TagItem
            icon={systemThickness}
            value={`${indicatorThickness} мм`}
            title="Толщина звукоизоляционной системы"
          />
        </Box>
      )}
      {!!indicatorRW && indicatorRW.toString() !== '0' && (
        <Box mb={1.5}>
          <TagItem
            icon={airNoise}
            value={`${indicatorRW} дБ`}
            title="Индекс изоляции воздушного шума общей конструкции Rw"
          />
        </Box>
      )}
      {!!indicatorDeltaRw && indicatorDeltaRw.toString() !== '0' && (
        <Box mb={1.5}>
          <TagItem
            icon={soundproofing}
            value={`${indicatorDeltaRw} дБ`}
            title="Индекс улучшения изоляции воздушного шума ΔRw"
          />
        </Box>
      )}
      {!!indicatorDeltaLNW && indicatorDeltaLNW.toString() !== '0' && (
        <TagItem
          icon={impactNoise}
          value={`${indicatorDeltaLNW} дБ`}
          title="Индекс улучшения изоляции ударного шума ΔLnw"
        />
      )}
    </Box>
  );
};

export default Indicators;

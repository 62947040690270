import { Box, Typography, Button, DialogProps } from '@material-ui/core';

import QModal from 'components/UI/QModal';
import { useCommonStyles } from 'styles/commonStyles';

interface ICloseWidgetConfirmModalProps extends DialogProps {
  onCloseModal: () => void;
  onCloseWidget: () => void;
}

const CloseWidgetConfirmModal: React.FC<ICloseWidgetConfirmModalProps> = props => {
  const { onCloseModal, onCloseWidget, ...rest } = props;
  const commonClasses = useCommonStyles();

  return (
    <QModal onClose={onCloseModal} {...rest}>
      <Box mb={1}>
        <Typography align="center" variant="body1" className={commonClasses.onSurfacePrimary}>
          Вы точно хотите закрыть виджет?
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography align="center" variant="body1" className={commonClasses.onSurfaceSecondary}>
          При этом введенные вами данные не сохранятся.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box mr={2}>
          <Button onClick={onCloseWidget} variant="contained" color="primary">
            Ок
          </Button>
        </Box>
        <Button onClick={onCloseModal} variant="contained" color="secondary">
          Отменить
        </Button>
      </Box>
    </QModal>
  );
};

export default CloseWidgetConfirmModal;

import { Box, Button, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import TextButton from 'components/UI/TextButton';
import { getWithSpace } from 'helpers/getWithSpace';
import { useAllMQ } from 'hooks/useAllMQ';
import { useCommonStyles } from 'styles/commonStyles';

interface IOrderCardProps {}

const OrderCard: React.FC<IOrderCardProps> = observer(() => {
  const { orderStore } = useRootStore();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { isMD } = useAllMQ();

  const totalSum = orderStore.cartInfo?.totalSum ?? 0;
  const totalSumWithDiscount = orderStore.cartInfo?.totalSumWithDiscount ?? 0;
  const discount = totalSumWithDiscount - totalSum;

  // Handlers
  const handleDownload = () => {
    saveAs('/agreement.pdf', 'agreement');
  };

  const handleNext = () => {
    orderStore.setIsPersonalDataScreen(true);
  };

  // Renders
  return (
    <div className={classes.wrap}>
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <Box mr={2}>
          <Typography className={commonClasses.onSurfaceSecondary} variant="body2">
            Общий объем: {orderStore.cartInfo?.totalVolumeGoods} м³
          </Typography>
        </Box>
        <Typography className={commonClasses.onSurfaceSecondary} variant="body2">
          Общий вес: {orderStore.cartInfo?.totalWeightGoods} кг
        </Typography>
      </Box>
      <Box mb={2}>
        <div className={classes.borderedWrap}>
          <div className={clsx(classes.item, classes.leftItem)}>
            <Typography className={commonClasses.onSurfacePrimary} variant="body2">
              Стоимость материалов
            </Typography>
          </div>
          <div className={clsx(classes.item, classes.rightItem)}>
            <Typography className={commonClasses.onSurfacePrimary} variant="body2">
              {getWithSpace(totalSum)} ₽
            </Typography>
          </div>
        </div>
        {discount !== 0 && (
          <div className={classes.borderedWrap}>
            <div className={clsx(classes.item, classes.leftItem)}>
              <Typography className={commonClasses.onSurfacePrimary} variant="body2">
                Скидка
              </Typography>
            </div>
            {orderStore.cartInfo && (
              <div className={clsx(classes.item, classes.rightItem)}>
                <Typography variant="body2" color="error">
                  {getWithSpace(Math.ceil(discount))} ₽
                </Typography>
              </div>
            )}
          </div>
        )}
        <div className={classes.borderedWrap}>
          <div className={clsx(classes.item, classes.leftItem)}>
            <Typography className={commonClasses.onSurfacePrimary} variant="subtitle1">
              Итого
            </Typography>
          </div>
          <div className={clsx(classes.item, classes.rightItem)}>
            <Typography className={commonClasses.onSurfacePrimary} variant="subtitle1">
              {getWithSpace(Math.ceil(totalSumWithDiscount))} ₽
            </Typography>
          </div>
        </div>
      </Box>
      {isMD ? (
        <Box mb={2}>
          <Button onClick={handleNext} fullWidth variant="contained" color="primary">
            Продолжить
          </Button>
        </Box>
      ) : (
        <Box mb={2}>
          <Button type="submit" fullWidth variant="contained" color="primary">
            Оформить заявку
          </Button>
        </Box>
      )}
      <Box display="flex" justifyContent="center">
        <Typography className={classes.policyText} variant="caption">
          Оформляя заказ, вы соглашаетесь с условиями
          <TextButton onClick={handleDownload} className={classes.policyLink}>
            пользовательского соглашения.
          </TextButton>
        </Typography>
      </Box>
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    padding: theme.spacing(3),
    background: '#F6F6F6',
    borderRadius: 8,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  borderedWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      bottom: 8,
      width: '100%',
      border: `1px dashed ${theme.palette.onSurface.tertiary}`,
      transform: 'translateX(-50%)',
      zIndex: 0,
    },
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  policyText: {
    color: theme.palette.onSurface.secondary,
    textAlign: 'center',
  },
  policyLink: {
    display: 'inline-block',
    padding: 0,
    color: theme.palette.primary.main,
    ...theme.typography.caption,
  },
  item: {
    position: 'relative',
    background: '#F6F6F6',
    zIndex: 1,
  },
  leftItem: {
    paddingRight: 4,
  },
  rightItem: {
    paddingLeft: 4,
  },
}));

export default OrderCard;

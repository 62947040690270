import { makeStyles, Theme } from '@material-ui/core';
import React, { useMemo } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import { useCommonStyles } from 'styles/commonStyles';

import discount from 'assets/icons/discount.png';

interface IImagesGalleryProps {
  paths: string[];
  isSmall?: boolean;
  isAction?: boolean;
}

const ImagesGallery: React.FC<IImagesGalleryProps> = props => {
  const { paths, isSmall, isAction } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const size = useMemo(() => (isSmall ? 24 : 32), [isSmall]);
  const rootClass = useMemo(() => (isSmall ? classes.rootSmall : classes.rootBig), [classes, isSmall]);

  const images = useMemo(
    () =>
      paths.map(path => ({
        original: path,
        thumbnail: path,
        originalAlt: 'Technosonus',
      })),
    [paths],
  );

  return (
    <div className={rootClass}>
      {isAction ? <img src={discount} width={size} height={size} className={commonClasses.imgAction} alt="" /> : null}
      {!!images.length && <ImageGallery items={images} showPlayButton={false} infinite={false} />}
    </div>
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  rootBig: {
    position: 'relative',
    maxWidth: 333,
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      margin: theme.spacing(0, 'auto', 2),
      maxWidth: 375,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
      margin: theme.spacing(0, 'auto'),
      paddingTop: theme.spacing(1),
    },
    '& .image-gallery-slides': {
      borderRadius: 8,
    },
    '& .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg': {
      height: 48,
      width: 24,
    },
    '& .image-gallery-slide': {
      maxWidth: '100%',
      maxHeight: 333,
      '& .image-gallery-image': {
        borderRadius: 8,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        [theme.breakpoints.down('xs')]: {
          maxHeight: '100%',
        },
      },
      [theme.breakpoints.down('sm')]: {
        maxHeight: 550,
      },
    },
    '& .fullscreen .image-gallery-slide': {
      maxHeight: '80%',
      '& .image-gallery-image': {
        objectFit: 'contain',
      },
    },
    '& .fullscreen .image-gallery-slide .image-gallery-image': {
      borderRadius: 0,
    },
    '& .fullscreen .image-gallery-slides': {
      borderRadius: 0,
    },
    '& .image-gallery-icon:hover': {
      color: theme.palette.primary.main,
    },
    '& .image-gallery-thumbnail': {
      width: '30%',
      height: 60,
      borderRadius: 8,
      '& .image-gallery-thumbnail-image': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 4,
        margin: 0,
      },
      '& .image-gallery-thumbnail-inner': {
        display: 'inline',
      },
      '&.active, &:hover': {
        border: `4px solid ${theme.palette.primary.main}`,
      },
    },
    '& .fullscreen .image-gallery-thumbnail': {
      height: 'auto',
    },
  },
  rootSmall: {
    position: 'relative',
    maxWidth: 196,
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      margin: theme.spacing(0, 'auto', 2),
      maxWidth: 250,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
      margin: theme.spacing(0, 'auto'),
      paddingTop: 0,
    },
    '& .image-gallery-slides': {
      borderRadius: 6,
    },
    '& .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg': {
      height: 40,
      width: 16,
    },
    '& .image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg': {
      height: 20,
      width: 20,
    },
    '& .image-gallery-slide': {
      maxWidth: '100%',
      maxHeight: 196,
      '& .image-gallery-image': {
        borderRadius: 6,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        [theme.breakpoints.down('xs')]: {
          maxHeight: '100%',
        },
      },
      [theme.breakpoints.down('sm')]: {
        maxHeight: 260,
      },
    },
    '& .fullscreen .image-gallery-slide': {
      maxHeight: '80%',
      '& .image-gallery-image': {
        objectFit: 'contain',
      },
    },
    '& .fullscreen .image-gallery-slide .image-gallery-image': {
      borderRadius: 0,
    },
    '& .fullscreen .image-gallery-slides': {
      borderRadius: 0,
    },
    '& .image-gallery-icon:hover': {
      color: theme.palette.primary.main,
    },
    '& .image-gallery-thumbnail': {
      width: '30%',
      height: 40,
      borderRadius: 5,
      '& .image-gallery-thumbnail-image': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 4,
        margin: 0,
      },
      '& .image-gallery-thumbnail-inner': {
        display: 'inline',
      },
      '&.active, &:hover': {
        border: `4px solid ${theme.palette.primary.main}`,
      },
    },
    '& .fullscreen .image-gallery-thumbnail': {
      height: 'auto',
    },
  },
}));

export default ImagesGallery;

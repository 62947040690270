import LocationApiRepository from './LocationApiRepository';
import LocationFactory from './LocationFactory';
import { LocationModel } from './models/LocationModel';

export default class LocationService {
  locationApi: LocationApiRepository;
  locationFactory: LocationFactory;

  constructor() {
    this.locationApi = new LocationApiRepository();
    this.locationFactory = new LocationFactory();
  }

  getLocationList = async (): Promise<LocationModel[]> => {
    const { data } = await this.locationApi.getLocationList();
    return this.locationFactory.createList<LocationModel>(LocationModel, data.data);
  };
}

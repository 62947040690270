import { Box, Typography } from '@material-ui/core';
import { useMemo } from 'react';

import { Nullable } from 'base/types/BaseTypes';
import { getWithSpace } from 'helpers/getWithSpace';
import { OrderCalcModel } from 'modules/order/models/OrderCalcModel';
import { useCommonStyles } from 'styles/commonStyles';

interface ICalcPriceProps {
  orderCalcInfo: Nullable<OrderCalcModel>;
  basePrice: number;
}

const CalcPrice: React.FC<ICalcPriceProps> = props => {
  const { orderCalcInfo, basePrice } = props;
  const commonClasses = useCommonStyles();

  const pricePerAreaCalc = useMemo(() => {
    const totalSum = orderCalcInfo?.totalSum ? orderCalcInfo.totalSum : 0;
    const totalArea = orderCalcInfo?.totalArea ? orderCalcInfo.totalArea : 0;
    const fixedTotalAreaValue = totalArea === 0 ? 1 : totalArea;

    return Math.ceil(totalSum / fixedTotalAreaValue);
  }, [orderCalcInfo]);

  const sum = useMemo(() => {
    if (orderCalcInfo) {
      return orderCalcInfo?.totalSum;
    }

    return basePrice;
  }, [basePrice, orderCalcInfo]);

  const pricePerArea = useMemo(() => {
    if (orderCalcInfo) {
      return pricePerAreaCalc;
    }

    if (sum) {
      return Math.ceil(sum) / 1;
    }

    return 0;
  }, [orderCalcInfo, pricePerAreaCalc, sum]);

  return (
    <Box display="flex" alignItems="center" mb={{ xxs: 2, sm: 3 }}>
      <Box mr={2}>
        <Typography className={commonClasses.primary} variant="h5" component="div">
          {getWithSpace(sum)} ₽
        </Typography>
      </Box>
      <Typography className={commonClasses.onSurfaceSecondary} variant="body1" component="div">
        {getWithSpace(pricePerArea)} ₽ за м²
      </Typography>
    </Box>
  );
};

export default CalcPrice;

import AbstractModel from 'base/AbstractModel';
import { Nullable } from 'base/types/BaseTypes';

import { ICategory } from '../types/CategoriesTypes';

export class Category extends AbstractModel {
  id: Nullable<number> = null;
  title: Nullable<string> = null;
  cat: Nullable<ICategory> = null;
  description: Nullable<string> = null;
  imageWebPath: Nullable<string> = null;
  previewWebPath: Nullable<string> = null;
  iconWebPath: Nullable<string> = null;
  widgetPreviewWebPath: Nullable<string> = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

import AbstractApiRepository from 'base/api/AbstractApiRepository';

import { OrderCalcDto } from './forms/OrderCalcDto';
import { OrderCreateDto } from './forms/OrderCreateDto';

export default class OrderApiRepository extends AbstractApiRepository {
  orderCalc = (data: OrderCalcDto) => {
    return this.apiClient.post({ url: '/order/calc', data });
  };

  orderCreate = (data: OrderCreateDto) => {
    return this.apiClient.post({ url: '/order/create', data });
  };
}

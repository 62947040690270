import { BaseNumberInput } from './NumberInputs';

export const RussianPhoneMaskInput = (props: any) => {
  const { onChange } = props;

  return BaseNumberInput(props, {
    onPaste: e => {
      const value = e.clipboardData.getData('text/plain');
      const rawNumber = getRussianPhoneRawNumber(value);

      setTimeout(() => {
        onChange({
          target: {
            name: props.name,
            value: rawNumber,
          },
        });
      }, 0);
    },
    format: '+7 ### ###-##-##',
    mask: '_',
  });
};

export const cleanUpPhoneMask = (maskedPhone: string) => {
  return maskedPhone.replace(/\D+/g, '');
};

export const getRussianPhoneRawNumber = (phone: string) => {
  const unmaskedPhone = cleanUpPhoneMask(phone);
  const rawNumber = unmaskedPhone.slice(-10);

  return rawNumber;
};

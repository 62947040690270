import { Box, Button, Grid, IconButton, makeStyles, MenuItem, Theme } from '@material-ui/core';
import { mdiAspectRatio, mdiCalculator, mdiHelpCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { IStringParams } from 'base/types/BaseTypes';
import { AreaNumberInput } from 'components/UI/NumberInputs';
import QSelect from 'components/UI/QSelect';
import QTextField from 'components/UI/QTextField';
import ResponsiveTooltip from 'components/UI/ResponsiveTooltip';
import TextButton from 'components/UI/TextButton';
import { useAllMQ } from 'hooks/useAllMQ';
import { routes } from 'screens/routes';

interface ISystemsFiltersProps {}

const SystemsFilters: React.FC<ISystemsFiltersProps> = observer(() => {
  const { systemsStore, locationStore, typesStore, orderStore } = useRootStore();
  const { values, setValues } = systemsStore;
  const { isXS, isSM } = useAllMQ();

  const classes = useStyles();
  const params = useParams<IStringParams>();

  // Effects
  useEffect(() => {
    systemsStore.resetValues();
    typesStore.resetTypes();
    typesStore.resetActiveTypes();

    if (locationStore.currentLocation && params?.subCategoryId) {
      typesStore.getTypes(Number(params.subCategoryId));
    }

    return () => {
      typesStore.resetTypes();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationStore.currentLocation, params.subCategoryId]);

  useEffect(() => {
    if (locationStore.currentLocation?.id && params?.subCategoryId && typesStore.typesList.length) {
      systemsStore.getSystems({
        ...values,
        region: locationStore.currentLocation?.id,
        cat: Number(params.subCategoryId),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, typesStore.typesList]);

  // Handlers
  const handleChangeSort = (e: React.ChangeEvent<{ name?: string; value: any }>) => {
    setValues({
      ...values,
      sort: e.target.value,
    });
  };

  const handleChangeType = (type: number) => {
    if (typesStore.activeTypesId.includes(type)) {
      typesStore.removeActiveTypesId(type);
    } else {
      typesStore.setActiveTypesId(type);

      setValues({
        ...values,
        type: typesStore.activeTypesId,
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    systemsStore.setArea(e.target.value);
    orderStore.setArea(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (locationStore.currentLocation?.id && params?.subCategoryId) {
      systemsStore.getSystems({
        ...values,
        region: locationStore.currentLocation.id,
        cat: Number(params.subCategoryId),
      });
    }
  };

  // Renders
  return (
    <div className={classes.topLine}>
      <Box mb={2}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={8}>
            <form onSubmit={handleSubmit}>
              <Box display="flex" alignItems="center" flexDirection={{ xxs: 'column', sm: 'row' }}>
                <Box
                  mr={{ xxs: 0, sm: 2 }}
                  mb={{ xxs: 1.5, sm: 0 }}
                  maxWidth={{ xxs: 'inherit', md: '330px' }}
                  width="100%"
                >
                  <QTextField
                    fullWidth
                    value={systemsStore.area}
                    placeholder="Введите площадь поверхности, м²"
                    startIconProps={{ icon: <Icon path={mdiAspectRatio} size={1} /> }}
                    InputProps={{ inputComponent: AreaNumberInput }}
                    onChange={handleChange}
                  />
                </Box>
                <Box maxWidth={{ xxs: 'inherit', sm: '148px' }} width="100%">
                  <Button
                    fullWidth
                    type="submit"
                    className={classes.blackButton}
                    startIcon={<Icon path={mdiCalculator} size={1} />}
                  >
                    Рассчитать
                  </Button>
                </Box>
              </Box>
            </form>
          </Grid>
          {!isSM && (
            <Grid container item xs={12} md justifyContent="flex-end">
              <Link to={routes.HelpToChooseScreen.path} className="router-link">
                <TextButton color="primary" endIcon={<Icon path={mdiHelpCircleOutline} size={1} />}>
                  Помощь в выборе
                </TextButton>
              </Link>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={8}>
            <Box display="flex" justifyContent={{ xxs: 'space-between', sm: 'flex-start' }} flexWrap="wrap" mb={-1}>
              {typesStore.typesList.map(
                ({ id, title, info }) =>
                  id &&
                  info && (
                    <div className={classes.filterBtnWrap} key={id}>
                      <ResponsiveTooltip placement="top" title={info}>
                        <Button
                          size="small"
                          fullWidth
                          variant="contained"
                          className={classes.filterBtn}
                          color={typesStore.activeTypesId.includes(id) ? 'primary' : 'secondary'}
                          endIcon={<Icon path={mdiHelpCircleOutline} size={1} />}
                          onClick={() => handleChangeType(id)}
                        >
                          {title}
                        </Button>
                      </ResponsiveTooltip>
                    </div>
                  ),
              )}
            </Box>
          </Grid>
          <Grid container item xs={12} md justifyContent={isSM ? 'space-between' : 'flex-end'}>
            {isSM && (
              <Box>
                <Link to={routes.HelpToChooseScreen.path} className="router-link">
                  {!isXS && (
                    <TextButton color="primary" endIcon={<Icon path={mdiHelpCircleOutline} size={1} />}>
                      Помощь в выборе
                    </TextButton>
                  )}
                  {isXS && (
                    <IconButton color="primary" size="small">
                      <Icon path={mdiHelpCircleOutline} size={1} />
                    </IconButton>
                  )}
                </Link>
              </Box>
            )}
            <Box maxWidth="236px" width="100%">
              <QSelect value={values.sort} onChange={handleChangeSort}>
                <MenuItem value="default">Сортировка</MenuItem>
                <MenuItem value="thicknessSort=asc">По увеличению толщины</MenuItem>
                <MenuItem value="thicknessSort=desc">По уменьшению толщины</MenuItem>
                <MenuItem value="priceSort=asc">По возрастанию цены</MenuItem>
                <MenuItem value="priceSort=desc">По убыванию цены</MenuItem>
              </QSelect>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  topLine: {
    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.outline.main}`,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2.5),
    },
  },
  filterBtn: {
    [theme.breakpoints.down('xs')]: {},
    '&.MuiButton-containedSecondary': {
      '& .MuiButton-endIcon': {
        color: theme.palette.onSurface.tertiary,
      },
    },
    '& .MuiButton-endIcon': {
      color: theme.palette.onPrimary.secondary,
    },
    '&.MuiButton-sizeSmall': {
      [theme.breakpoints.down('xs')]: {
        padding: '4.5px 4px',
      },
    },
  },
  filterBtnWrap: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flex: '1 1 auto',
    },
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginRight: theme.spacing(1.5),
      },
    },
  },
  blackButton: {
    color: theme.palette.onPrimary.primary,
    background: theme.palette.onSurface.primary,
    '&:hover': {
      opacity: '0.8',
      color: theme.palette.onPrimary.primary,
      background: theme.palette.onSurface.primary,
    },
  },
}));

export default SystemsFilters;

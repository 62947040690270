import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useLocation } from 'react-router';

import { useRootStore } from 'base/hooks/useRootStore';
import Routes from 'base/routes/components/Routes';
import Header from 'components/Header';
import Loader from 'components/UI/Loader';
import ChooseLocation from 'screens/choose-location/ChooseLocation';
import { routes } from 'screens/routes';
import { getTheme } from 'styles/muiTheme';

const App: React.FC = observer(() => {
  const { locationStore, settingsStore, orderStore } = useRootStore();
  const location = useLocation();

  useEffect(() => {
    const paramsArr = location.search?.split('=');
    const agentId = paramsArr[paramsArr.length - 1];

    if (agentId) {
      orderStore.setValues({
        ...orderStore.values,
        agentId,
      });

      settingsStore.getSettings(agentId);
    }
  }, [location.search, settingsStore, orderStore]);

  // Renders
  const renderContent = () => {
    if (locationStore.loading) {
      return <Loader minHeight="100vh" />;
    }

    if (!locationStore.currentLocation) {
      return <ChooseLocation />;
    }

    return (
      <>
        <Header />
        <Routes routes={routes} />
        <ReactNotification />
      </>
    );
  };

  return (
    <ThemeProvider theme={getTheme(settingsStore.color)}>
      <CssBaseline />
      {orderStore.values.agentId && renderContent()}
    </ThemeProvider>
  );
});

export default App;

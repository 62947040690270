import { Box, Button, Typography } from '@material-ui/core';
import { mdiPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Link } from 'react-router-dom';

import Loader from 'components/UI/Loader';
import { useAllMQ } from 'hooks/useAllMQ';
import { routes } from 'screens/routes';
import { useCommonStyles } from 'styles/commonStyles';

interface IAddCartButtonsProps {
  path: string;
  isAddedCart: boolean;
  isAddedCartLoading: boolean;
  onHandleAddCart: () => void;
  isBottom?: boolean;
}

const AddCartButtons: React.FC<IAddCartButtonsProps> = props => {
  const { path, isAddedCart, isAddedCartLoading, onHandleAddCart, isBottom } = props;

  const { isXS } = useAllMQ();
  const commonClasses = useCommonStyles();

  // Renders
  if (isAddedCart) {
    return (
      <Box maxWidth={{ xxs: '100%', sm: 'max-content' }}>
        <Link to={routes.CartScreen.path} className="router-link">
          <Button fullWidth={isXS} variant="contained" color="primary">
            <Box mr={1}>
              <Typography variant="caption" className={commonClasses.onPrimarySecondary}>
                Добавлено
              </Typography>
            </Box>
            Перейти к оформлению
          </Button>
        </Link>
        {!isBottom && (
          <Box mt={2}>
            <Link to={path} className="router-link">
              <Button fullWidth variant="contained" color="secondary">
                Продолжить выбор систем
              </Button>
            </Link>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Button
      fullWidth={isXS}
      color="primary"
      variant="contained"
      startIcon={<Icon path={mdiPlus} size={1} />}
      onClick={onHandleAddCart}
    >
      <Typography noWrap>Добавить в заявку</Typography>
      {isAddedCartLoading && <Loader size={24} color="primary" />}
    </Button>
  );
};

export default AddCartButtons;

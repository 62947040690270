import { Box, Typography, IconButton } from '@material-ui/core';
import { mdiClose } from '@mdi/js';
import { Icon } from '@mdi/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect } from 'react';
import { useParams } from 'react-router';

import { useRootStore } from 'base/hooks/useRootStore';
import { IStringParams } from 'base/types/BaseTypes';
import Loader from 'components/UI/Loader';
import QModal from 'components/UI/QModal';
import { useAllMQ } from 'hooks/useAllMQ';
import { GoodTemplate } from 'modules/goods/models/GoodTemplate';
import { useCommonStyles } from 'styles/commonStyles';
import { useCustomTypography } from 'styles/customTypography';

import SCItem from './SystemComposition/SCItem';
import SCSubItem from './SystemComposition/SCSubItem';
import TableHead from './SystemComposition/TableHead';

interface ISystemCompositionProps {}

const SystemComposition: React.FC<ISystemCompositionProps> = observer(() => {
  const { goodsStore, orderStore } = useRootStore();
  const params = useParams<IStringParams>();

  const { isXS } = useAllMQ();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  // Effects
  useEffect(() => {
    if (params?.systemId) {
      goodsStore.getGoods(Number(params.systemId));
    }
  }, [goodsStore, params.systemId]);

  // Handlers
  const handleCloseChooseModal = () => {
    goodsStore.setChooseModal(false);
    goodsStore.setCurrentChangeGoodsList([]);
    goodsStore.setCurrentGood(null);
  };

  const handleSelect = (selectedGood: GoodTemplate) => {
    if (goodsStore.currentGood && selectedGood.good) {
      goodsStore.setGoodsList(goodsStore.currentGood, selectedGood);
      goodsStore.setFlatGoodsList();
    }

    if (orderStore.area.length) {
      orderStore.orderCalc(orderStore.orderValues);
    }
  };

  // Renders
  const renderSubItems = () => {
    if (goodsStore.changeGoodsLoading) {
      return <Loader />;
    }

    if (goodsStore.currentChangeGoodsList.length) {
      return (
        <div>
          {goodsStore.currentChangeGoodsList.map(item =>
            item.good ? (
              <SCSubItem
                key={item.good.id}
                good={item.good}
                selectedId={goodsStore.currentGood && goodsStore.currentGood.id}
                onHandleSelect={() => handleSelect(item)}
              />
            ) : null,
          )}
        </div>
      );
    }
  };

  return (
    <>
      <TableHead />
      <div>
        {goodsStore.goodsList.map(({ good, autoChangeGoods }) => {
          const mainCalculatedGood = orderStore.getCalculatedGood(good?.id);

          return (
            <Fragment key={good?.id || 'fragment'}>
              {good && <SCItem good={good} calcGood={mainCalculatedGood} />}
              {autoChangeGoods.map((autoGood, idx) => {
                const autoCalculatedGood = orderStore.getCalculatedGood(autoGood.id);
                return (
                  autoGood && (
                    <SCItem key={`auto-${autoGood.id}-${idx}`} good={autoGood} calcGood={autoCalculatedGood} />
                  )
                );
              })}
            </Fragment>
          );
        })}

        {isXS && (
          <QModal open={goodsStore.chooseModal} onClose={handleCloseChooseModal}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography className={clsx(commonClasses.onSurfacePrimary, typography.headline6Strong)}>
                Все варианты
              </Typography>
              <IconButton onClick={handleCloseChooseModal} size="small" className={commonClasses.onSurfaceSecondary}>
                <Icon path={mdiClose} size={1} />
              </IconButton>
            </Box>

            {renderSubItems()}
          </QModal>
        )}
      </div>
    </>
  );
});

export default SystemComposition;

import { createTheme, Theme, ThemeOptions } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';

const getThemeOptions = (primaryColor: string) => {
  const themeOptions: ThemeOptions = {
    breakpoints: {
      values: {
        xxs: 0,
        xs: 375,
        sm: 576,
        md: 768,
        lg: 960,
      },
    },
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: 'rgba(33, 33, 33, 0.06)',
      },
      error: {
        main: '#FF3B30',
      },
      surface: {
        main: '#FFFFFF',
      },
      outline: {
        main: 'rgba(34, 34, 34, 0.24)',
      },
      overlay: {
        main: 'rgba(33, 33, 33, 0.06)',
      },
      onSurface: {
        primary: 'rgba(0, 0, 0, 0.94)',
        secondary: 'rgba(34, 34, 34, 0.6)',
        tertiary: 'rgba(34, 34, 34, 0.34)',
      },
      onPrimary: {
        primary: '#FFFFFF',
        secondary: 'rgba(250, 250, 250, 0.67)',
      },
    },
    typography: {
      fontFamily: "'Roboto', sans-serif",
      h5: {
        fontSize: '24px',
        lineHeight: '36px',
      },
      h6: {
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
      },
      body1: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.5px',
      },
      body2: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
      },
      button: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '23px',
        letterSpacing: '0.15px',
        textTransform: 'inherit',
      },
      caption: {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
      },
      overline: {
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '16px',
        letterSpacing: '1.5px',
        textTransform: 'uppercase',
      },
      subtitle1: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
      },
      subtitle2: {
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.1px',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            scrollBehavior: 'smooth',
          },
          body: {
            backgroundColor: '#fff !important',
            fontWeight: 'normal',
          },
          main: {
            flex: '1 0 auto',
            paddingBottom: 24,
          },
          '#root': {
            position: 'relative',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          },
          ul: {
            margin: 0,
            padding: 0,
            listStyleType: 'none',
          },
          '.router-link': {
            textDecoration: 'none',
          },
        },
      },
      MuiButton: {
        root: {
          boxShadow: 'none !important',
          padding: '8.5px 16.3px',
          '&:disabled': {
            opacity: '0.48',
          },
        },
        outlined: {
          padding: '7.5px 14.5px',
        },
        outlinedPrimary: {
          color: primaryColor,
          border: `1px solid rgba(34, 34, 34, 0.24)`,
        },
        containedPrimary: {
          color: '#fff',
          background: primaryColor,
          '&$disabled': {
            background: primaryColor,
            color: '#fff',
          },
        },
        containedSecondary: {
          color: 'rgba(0, 0, 0, 0.94)',
          '& $startIcon': {
            color: primaryColor,
          },
          '& $endIcon': {
            color: primaryColor,
          },
        },
        sizeSmall: {
          padding: '4.5px 16px',
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '23px',
          '& $startIcon': {
            marginRight: 8,
          },
          '& $endIcon': {
            marginLeft: 8,
          },
        },
        startIcon: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 20,
          height: 20,
          marginRight: 12,
          '& > svg': {
            width: '100%',
          },
        },
        endIcon: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 20,
          height: 20,
          marginLeft: 12,
          '& > svg': {
            width: '100%',
          },
        },
        text: {
          backgroundColor: 'transparent',
          transition: 'all .4s ease',
          padding: '8.5px 16.3px',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        textPrimary: {
          color: primaryColor,
          '&:hover': {
            background: 'transparent !important',
            color: '#006b9a',
          },
        },
      },
      MuiIconButton: {
        root: {
          width: 40,
          height: 40,
          padding: 0,
          borderRadius: 4,
          '& .MuiTouchRipple-root *': {
            borderRadius: '4px !important',
          },
        },
        sizeSmall: {
          width: 32,
          height: 32,
          padding: 5,
        },
        colorPrimary: {
          color: '#fff',
          backgroundColor: primaryColor,
          '&:hover': {
            backgroundColor: 'rgb(0, 107, 154)',
          },
        },
        colorSecondary: {
          color: 'rgba(0, 0, 0, 0.94)',
          backgroundColor: 'rgba(33, 33, 33, 0.06)',
        },
      },
      MuiInputBase: {
        root: {
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.15px',
          color: 'rgba(0, 0, 0, 0.94)',
          padding: '0',
          '&$focused': {
            '& .MuiIconButton-root': {
              color: primaryColor,
            },
          },
          '&:focus': {
            outline: 'none',
          },
          '&::-webkit-input-placeholder': {
            color: 'rgba(34, 34, 34, 0.6)',
          },
          '&:-ms-input-placeholder': {
            color: 'rgba(34, 34, 34, 0.6)',
          },
          '&::-moz-placeholder': {
            color: 'rgba(34, 34, 34, 0.6)',
          },
          '&:-moz-placeholder': {
            color: 'rgba(34, 34, 34, 0.6)',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          border: 'none',
          boxShadow: 'none',
          '&:hover:not($disabled) $notchedOutline': {
            borderColor: 'rgba(34, 34, 34, 0.32)',
          },
          '&:focus:not($disabled)': {
            outline: 'none',
            background: '#FFFFFE',
            '&$notchedOutline': {
              borderColor: '#0099DC !important',
            },
          },
          '&$disabled': {
            opacity: '0.48',
          },
          '&$error': {
            background: 'rgba(255, 59, 48, 0.08)',
            color: 'rgba(0, 0, 0, 0.94) !important',
            '& $notchedOutline': {
              borderColor: '#FF3B30 !important',
            },
            '& .MuiIconButton-root': {
              color: 'rgba(34, 34, 34, 0.34)',
            },
          },
        },
        input: {
          padding: '10.5px 12px',
        },
        notchedOutline: {
          border: '1px solid rgba(34, 34, 34, 0.24)',
          borderWidth: '1px !important',
          transition: transitions.create('border-color'),
        },
        adornedStart: {
          paddingLeft: 0,
          '& $input': {
            paddingLeft: '12px',
          },
        },
        adornedEnd: {
          paddingRight: 0,
          '& $input': {
            paddingRight: '12px',
          },
        },
        inputMultiline: {
          padding: '0 !important',
        },
      },
      MuiFormLabel: {
        root: {
          display: 'inline-block',
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: '0.4px',
          color: 'rgba(0, 0, 0, 0.94)',
          marginBottom: 8,
        },
      },
      MuiFormHelperText: {
        root: {
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: '0.4px',
          margin: '4px 12px 0',
        },
        contained: {
          margin: '4px 12px 0',
        },
      },
      MuiBreadcrumbs: {
        root: {},
        separator: {
          width: '14px',
          height: '14px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 2,
          marginLeft: 2,
          color: 'rgba(34, 34, 34, 0.34)',
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        root: {},
      },
      MuiContainer: {
        root: {
          '@media only screen and (max-width: 375px)': {
            padding: '0 10px',
          },
        },
      },
    },
  };

  return themeOptions;
};

export const getTheme = (primaryColor: string | null): Theme => {
  const themeOptions = primaryColor ? getThemeOptions(primaryColor) : getThemeOptions('#0099DC');

  return createTheme(themeOptions);
};

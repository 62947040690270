import { Box, Container, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { makePath } from 'base/routes/utils/makePath';
import Loader from 'components/UI/Loader';
import { useAllMQ } from 'hooks/useAllMQ';
import { routes } from 'screens/routes';
import { useCustomTypography } from 'styles/customTypography';

import CategoriesCard from './components/CategoriesCard';

const CategoriesScreen: React.FC = observer(() => {
  const { categoriesStore, systemsStore, orderStore } = useRootStore();

  const { isXS } = useAllMQ();
  const typography = useCustomTypography();

  // Effects
  useEffect(() => {
    categoriesStore.getCategories();
    systemsStore.setArea('');
    orderStore.setArea('');
  }, [categoriesStore, orderStore, systemsStore]);

  if (categoriesStore.loading) {
    return <Loader minHeight="100vh" />;
  }

  return (
    <Box pt={3}>
      <Container maxWidth="lg">
        <Box mb={{ xxs: 2.5, sm: 3 }}>
          <Typography variant={isXS ? 'inherit' : 'h5'} className={clsx({ [typography.headline6Strong]: isXS })}>
            Выберите область применения
          </Typography>
        </Box>
        <Grid container spacing={isXS ? 2 : 3}>
          {categoriesStore.categoriesList.map(
            item =>
              item.id && (
                <Grid key={item.id} item xs={12} sm={6} md={4}>
                  <CategoriesCard
                    path={makePath(routes.SubCategoriesScreen.path, [{ p: 'categoryId', v: item.id }])}
                    {...item}
                  />
                </Grid>
              ),
          )}
        </Grid>
      </Container>
    </Box>
  );
});

export default CategoriesScreen;

import AbstractApiRepository from 'base/api/AbstractApiRepository';

import { EntityDto } from './forms/EntityDto';
import { SystemDto } from './forms/SystemDto';

export default class SystemsApiRepository extends AbstractApiRepository {
  getSystems = (data: SystemDto) => {
    return this.apiClient.post({ url: '/systems', data });
  };

  getSystemById = (systemId: number) => {
    return this.apiClient.get({ url: `/systems/${systemId}` });
  };

  getEntityInfo = (data: EntityDto) => {
    return this.apiClient.post({ url: '/entity_info', data });
  };
}

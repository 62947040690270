import AbstractDto from 'base/AbstractDto';
import { Nullable } from 'base/types/BaseTypes';

import { ISerializedOrderCreate } from '../types/OrderTypes';

export class OrderCreateDto extends AbstractDto {
  region: Nullable<number> = null;
  discount: Nullable<number> = null;
  customer_fio: Nullable<string> = null;
  customer_phone: Nullable<string> = null;
  customer_email: Nullable<string> = null;
  customer_description: Nullable<string> = null;
  isReport: boolean = false;
  isSendEmail: boolean = true;
  order: ISerializedOrderCreate[] = [];
  service_discount?: Nullable<number> = null;
  agentId: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

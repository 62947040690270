import { Box, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { mdiMapMarkerOutline, mdiClose, mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router-dom';

import { Nullable } from 'base/types/BaseTypes';
import TextButton from 'components/UI/TextButton';
import { useAllMQ } from 'hooks/useAllMQ';
import { Breadcrumb } from 'modules/breadcrumbs/models/Breadcrumb';
import { LocationModel } from 'modules/location/models/LocationModel';
import { routes } from 'screens/routes';
import { useCommonStyles } from 'styles/commonStyles';

interface IMobileHeaderProps {
  title?: string;
  isCartScreen: boolean;
  isOtherScreens: boolean;
  cartItemCount?: number;
  breadcrumbs: Breadcrumb[];
  currentLocation: Nullable<LocationModel>;
  onHandleOpenMenu: (e: React.MouseEvent<HTMLElement>) => void;
  onCloseWidget: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onHandleGoBack: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const MobileHeader: React.FC<IMobileHeaderProps> = observer(props => {
  const {
    title,
    isCartScreen,
    isOtherScreens,
    cartItemCount,
    currentLocation,
    onHandleOpenMenu,
    onCloseWidget,
    onHandleGoBack,
  } = props;

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const location = useLocation();
  const { isXS } = useAllMQ();

  const isFirstScreen = location.pathname === '/';

  // Renders
  const renderLeftColumn = () => {
    if (isOtherScreens) {
      return (
        <Box display="flex" alignItems="center">
          <Box mr={{ xxs: 1, sm: 2 }}>
            <IconButton onClick={onHandleGoBack} className={commonClasses.onSurfacePrimary}>
              <Icon path={mdiArrowLeft} size={1} />
            </IconButton>
          </Box>
          <Typography className={commonClasses.onSurfacePrimary} variant="subtitle2" noWrap>
            {title}
          </Typography>
        </Box>
      );
    }

    return (
      <Box display="flex" alignItems="center">
        {!isFirstScreen && (
          <Box mr={{ xxs: 0, md: 2 }}>
            <IconButton onClick={onHandleGoBack} className={commonClasses.onSurfacePrimary}>
              <Icon path={mdiArrowLeft} size={1} />
            </IconButton>
          </Box>
        )}
        <Typography variant="subtitle2" className={commonClasses.onSurfacePrimary}>
          Калькулятор
        </Typography>
      </Box>
    );
  };

  const renderRightColumn = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {!!cartItemCount && !isCartScreen ? (
          <Link className="router-link" to={routes.CartScreen.path}>
            <Box display="flex" alignItems="center">
              <TextButton color="primary" className={classes.cartBtn}>
                <Typography variant="body1">Моя заявка</Typography>
              </TextButton>
              <div className={classes.itemsCount}>{cartItemCount}</div>
            </Box>
          </Link>
        ) : (
          currentLocation && (
            <Box mr={{ sm: 1 }}>
              <TextButton
                color="primary"
                startIcon={!isXS && <Icon path={mdiMapMarkerOutline} size={1} />}
                onClick={onHandleOpenMenu}
                className={classes.locationBtn}
                classes={{ startIcon: classes.startIcon }}
              >
                <div className={classes.textEllipsis}>{currentLocation?.title}</div>
              </TextButton>
            </Box>
          )
        )}
        <IconButton onClick={onCloseWidget} className={commonClasses.onSurfaceSecondary}>
          <Icon path={mdiClose} size={1} />
        </IconButton>
      </Box>
    );
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={6}>
        {renderLeftColumn()}
      </Grid>
      <Grid item xs={6}>
        {renderRightColumn()}
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: '11.5px 0',
    background: '#fff',
    borderBottom: `1px solid ${theme.palette.outline.main}`,
  },
  locationBtn: {
    textAlign: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  cartBtn: {
    padding: '0',
    marginRight: theme.spacing(1),
  },
  itemsCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 16,
    height: 16,
    ...theme.typography.overline,
    color: theme.palette.onPrimary.primary,
    background: theme.palette.onSurface.tertiary,
    borderRadius: '50%',
    padding: 2,
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 150,
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.body2,
      maxWidth: 100,
    },
    [theme.breakpoints.up('sm')]: {
      ...theme.typography.body1,
    },
  },
  startIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default MobileHeader;

import { Button, makeStyles, Theme } from '@material-ui/core';
import { mdiChevronDown } from '@mdi/js';
import { Icon } from '@mdi/react';

interface IShowButtonProps {
  show?: boolean;
  onHandleShowSubItems: () => void;
}

const ShowButton: React.FC<IShowButtonProps> = ({ show, onHandleShowSubItems }) => {
  const classes = useStyles();

  if (show) {
    return <></>;
  }

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        color="secondary"
        endIcon={<Icon path={mdiChevronDown} size={1} />}
        className={classes.moreBtn}
        onClick={onHandleShowSubItems}
      >
        Доступны другие варианты
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  moreBtn: {
    '& .MuiButton-endIcon': {
      color: theme.palette.onSurface.tertiary,
    },
  },
}));

export default ShowButton;

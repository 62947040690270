import { configure } from 'mobx';
import { createRoot } from 'react-dom/client';
// import * as Sentry from '@sentry/browser';
import { Router } from 'react-router-dom';

import history from 'base/routes/history';

import App from './App';
import reportWebVitals from './reportWebVitals';

// StrictMode для Mobx
configure({ enforceActions: 'observed' });

// Sentry для продакшна, отлавливает ошибки приложения, включается перед публикацией
// if (process.env.NODE_ENV === 'production') {
//     Sentry.init({ dsn: 'project_dsn_link' });
// }

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Router history={history}>
    <App />
  </Router>,
);

// Помогает при верстке, после можно отключить, так как при интеграции с апи может помешать
if (module.hot) {
  module.hot.accept();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

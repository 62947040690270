import { Box, IconButton, CircularProgress } from '@material-ui/core';
import { mdiAspectRatio, mdiCalculator } from '@mdi/js';
import { Icon } from '@mdi/react';

import { Nullable } from 'base/types/BaseTypes';
import { AreaNumberInput } from 'components/UI/NumberInputs';
import QTextField from 'components/UI/QTextField';

interface ICalcFormProps {
  loading: boolean;
  areaError: boolean;
  area: Nullable<string>;
  onHandleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const CalcForm: React.FC<ICalcFormProps> = props => {
  const { loading, area, areaError, onHandleChange, onHandleSubmit } = props;

  return (
    <Box mb={{ xxs: 2, sm: 3 }}>
      <form onSubmit={onHandleSubmit}>
        <Box display="flex" alignItems="flex-start">
          <Box mr={2} maxWidth={{ xxs: '100%', sm: '160px' }} width="100%">
            <QTextField
              fullWidth
              name="area"
              value={area}
              placeholder="м²"
              startIconProps={{ icon: <Icon path={mdiAspectRatio} size={1} /> }}
              onChange={onHandleChange}
              InputProps={{ inputComponent: AreaNumberInput }}
              error={areaError}
              helperText={areaError ? 'Введите площадь' : undefined}
            />
          </Box>
          <IconButton type="submit" color="secondary">
            {loading ? <CircularProgress size={24} color="primary" /> : <Icon path={mdiCalculator} size={1} />}
          </IconButton>
        </Box>
      </form>
    </Box>
  );
};

export default CalcForm;

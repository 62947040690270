import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import TextButton from 'components/UI/TextButton';
import { getWithSpace } from 'helpers/getWithSpace';
import { useAllMQ } from 'hooks/useAllMQ';
import { IResponseOrder } from 'modules/order/types/OrderTypes';
import { useCommonStyles } from 'styles/commonStyles';

interface ICartItemProps {
  id: number;
  data: IResponseOrder;
  onHandleDelete: (id: number) => void;
}

const CartItem: React.FC<ICartItemProps> = observer(props => {
  const { id, data, onHandleDelete } = props;
  const { orderStore } = useRootStore();

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { isXS } = useAllMQ();

  const currentSystem = orderStore.cartRequestState?.order.find(item => item.system === data.system?.id);
  const systemPath = currentSystem?.systemPath ?? '';

  const renderTitle = () => {
    return (
      <Link className="router-link" to={systemPath}>
        <Typography variant={isXS ? 'subtitle2' : 'subtitle1'} className={commonClasses.primary}>
          {data.system?.title}
        </Typography>
      </Link>
    );
  };

  return (
    <Box display={{ sm: 'flex' }}>
      {data.system?.imagesWebPath?.length && (
        <Box display={{ xxs: 'flex', sm: 'block' }} mb={{ xxs: 1.5, sm: 0 }}>
          <div className={classes.imgWrap}>
            <img className={classes.img} src={data.system?.imagesWebPath[0]} alt="" />
          </div>
          {isXS && renderTitle()}
        </Box>
      )}
      <div>
        {!isXS && <Box mb={1.5}>{renderTitle()}</Box>}
        <Box mb={1.5} display="flex" alignItems="center">
          <Box mr={2}>
            <Typography className={commonClasses.onSurfaceSecondary} variant="body2">
              Площадь: {data.area}&nbsp;м²
            </Typography>
          </Box>
          <Box mr={2}>
            <Typography className={commonClasses.onSurfaceSecondary} variant="body2">
              Объем: {data.totalVolumeGoods}&nbsp;м³
            </Typography>
          </Box>
          <Box>
            <Typography className={commonClasses.onSurfaceSecondary} variant="body2">
              Вес: {data.totalWeightGoods}&nbsp;кг
            </Typography>
          </Box>
        </Box>
        <Box mb={1.5}>
          <Typography variant="body2" className={commonClasses.onSurfacePrimary}>
            Стоимость материалов: {getWithSpace(data.totalSumGoods)}&nbsp;₽
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1" className={commonClasses.onSurfacePrimary}>
            {getWithSpace(data.totalSumGoods)} ₽
          </Typography>
          <TextButton onClick={() => onHandleDelete(id)} className={classes.removeBtn}>
            Удалить
          </TextButton>
        </Box>
      </div>
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  imgWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(3),
    width: 60,
    height: 60,
    minWidth: 60,
    minHeight: 60,
    borderRadius: 8,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(2),
    },
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  removeBtn: {
    padding: 0,
    textAlign: 'inherit',
    color: theme.palette.onSurface.secondary,
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

export default CartItem;

import { Container, makeStyles, Theme } from '@material-ui/core';
import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useRootStore } from 'base/hooks/useRootStore';
import NotificationUtil from 'base/utils/NotificationUtil';
import Loader from 'components/UI/Loader';
import { useAllMQ } from 'hooks/useAllMQ';
import { HandleAction } from 'modules/order/types/OrderTypes';
import { routes } from 'screens/routes';

import DeleteConfirmModal from './components/DeleteConfirmModal';
import OrderForm from './components/OrderForm';
import PersonalData from './components/PersonalData';
import SuccessModal from './components/SuccessModal';

const CartScreen: React.FC = observer(() => {
  const { orderStore } = useRootStore();
  const history = useHistory();

  const { isMD } = useAllMQ();
  const classes = useStyles();

  const { values, setValues } = orderStore;
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [deleteModal, setDeleteModal] = useState(false);

  // Effects
  useEffect(() => {
    if (orderStore.cartRequestState) {
      orderStore.orderCalc(orderStore.cartRequestState, false, true);
    }

    return () => {
      orderStore.resetOrderCalcValues();
    };
  }, [orderStore, orderStore.cartRequestState]);

  useEffect(() => {
    if (!orderStore.cartRequestState) {
      history.push(routes.CategoriesScreen.path);
    }
  }, [history, orderStore.cartRequestState]);

  const getAction = useCallback(
    (action: HandleAction) => {
      if (!orderStore.cartInfo?.pdfWebPath) {
        return;
      }

      switch (action) {
        case 'download':
          saveAs(orderStore.cartInfo.pdfWebPath);
          NotificationUtil.showSuccess('Расчет успешно скачан!');
          break;
        case 'copy':
          window.parent.postMessage({ type: 'pdfWebPathCopy', path: orderStore.cartInfo.pdfWebPath }, '*');
          navigator.clipboard.writeText(orderStore.cartInfo.pdfWebPath);
          NotificationUtil.showSuccess('Ссылка успешно скопирована!');
          break;
      }
    },
    [orderStore.cartInfo],
  );

  // Handlers
  const handleCloseModal = () => {
    setDeleteModal(false);
  };

  const handleOpenModal = (id: number) => {
    setDeleteModal(true);
    setCurrentId(id);
  };

  const handleCloseOrderCreatedModal = () => {
    orderStore.setOrderCreateModal(false);
    history.push(routes.CategoriesScreen.path);
    orderStore.setCartRequestState(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleDelete = () => {
    if (orderStore.cartRequestState && currentId !== null) {
      const filteredOrder = orderStore.cartRequestState?.order.filter((_, idx) => currentId !== idx);

      orderStore.setCartRequestState({
        ...orderStore.cartRequestState,
        order: filteredOrder,
      });

      setDeleteModal(false);

      if (!filteredOrder.length) {
        history.push('/');
      }
    }
  };

  const handleClick = (action: HandleAction) => {
    if (!orderStore.cartRequestState) {
      return;
    }

    if (orderStore.cartInfo?.pdfWebPath) {
      getAction(action);
      return;
    }

    orderStore
      .orderCalc({ ...orderStore.cartRequestState, isReport: true }, false, true)
      .then(() => {
        getAction(action);
      })
      .catch(() => {});
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (values.phone.length === 10) {
      orderStore.orderCreate(values);
    } else {
      NotificationUtil.showError('Номер должен состоять из 11 цифр');
    }
  };

  const renderDesktop = () => {
    return (
      <>
        <div className={classes.col}>
          <PersonalData values={values} onHandleChange={handleChange} />
        </div>
        <div className={classes.col}>
          <OrderForm onHandleOpenModal={handleOpenModal} onHandleClick={handleClick} />
        </div>
      </>
    );
  };

  const renderMobile = () => {
    if (orderStore.isPersonalDataScreeen) {
      return (
        <div className={classes.col}>
          <PersonalData values={values} onHandleChange={handleChange} />
        </div>
      );
    }

    return (
      <div className={classes.col}>
        <OrderForm onHandleOpenModal={handleOpenModal} onHandleClick={handleClick} />
      </div>
    );
  };

  // Renders
  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit}>
        {orderStore.orderLoading && <Loader isAbsolute minHeight="100vh" />}
        <div className={classes.wrap}>{isMD ? renderMobile() : renderDesktop()}</div>
      </form>

      <SuccessModal onHandleClose={handleCloseOrderCreatedModal} open={orderStore.orderCreateModal} />
      <DeleteConfirmModal open={deleteModal} onHandleClose={handleCloseModal} onHandleDelete={handleDelete} />
    </Container>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(0, -3),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '0.1px',
        height: '100vh',
        background: theme.palette.outline.main,
      },
    },
  },
  col: {
    position: 'relative',
    flexBasis: '50%',
    maxWidth: '50%',
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
}));

export default CartScreen;

import { Box, Button, Container, makeStyles, Theme, Typography } from '@material-ui/core';
import { mdiDownloadOutline, mdiPlayCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useRootStore } from 'base/hooks/useRootStore';
import { IStringParams } from 'base/types/BaseTypes';
import ImagesGallery from 'components/UI/ImagesGallery';
import Loader from 'components/UI/Loader';
import { getWithSpace } from 'helpers/getWithSpace';
import { useAllMQ } from 'hooks/useAllMQ';
import { useCommonStyles } from 'styles/commonStyles';
import { useCustomTypography } from 'styles/customTypography';

const SystemCompositionDetailScreen: React.FC = observer(() => {
  const { goodsStore } = useRootStore();
  const { goodDetail } = goodsStore;

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();
  const { isXS } = useAllMQ();
  const params = useParams<IStringParams>();

  // Effects
  useEffect(() => {
    if (params.systemCompositionId) {
      goodsStore.getGoodById(Number(params.systemCompositionId));
    }

    return () => {
      goodsStore.resetGoodDetail();
    };
  }, [goodsStore, params.systemCompositionId]);

  // Handlers
  const handleDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (goodDetail?.documentWebPath) {
      saveAs(goodDetail?.documentWebPath, goodDetail.title || '');
    }
  };

  // Renders
  if (goodsStore.loading) {
    return <Loader minHeight="calc(100vh - 112px)" />;
  }

  return (
    <Box pt={3}>
      <Container maxWidth="lg">
        <Box mb={{ xxs: 4, sm: 3 }} display="flex" flexDirection={{ xxs: 'column', md: 'row' }}>
          {!!goodDetail?.imagesWebPath?.length && (
            <ImagesGallery isSmall paths={goodDetail.imagesWebPath} isAction={goodDetail.isAction} />
          )}
          <div>
            {goodDetail?.title && (
              <Box mb={{ xxs: 2, sm: 3 }}>
                <Typography
                  component="h5"
                  variant={!isXS ? 'h5' : 'inherit'}
                  className={clsx(commonClasses.onSurfacePrimary, { [typography.headline6Strong]: isXS })}
                >
                  {goodDetail.title}
                </Typography>
              </Box>
            )}
            <Box display="flex" alignItems="center" mb={{ xxs: 2, sm: 3 }}>
              {goodDetail?.pricePerOne && (
                <Box mr={2}>
                  <Typography variant="h5" className={commonClasses.primary}>
                    {getWithSpace(goodDetail.pricePerOne)} ₽
                  </Typography>
                </Box>
              )}
              {goodDetail?.pricePerOne && goodDetail.qty && goodDetail.measure && (
                <Typography variant="body1" className={commonClasses.onSurfaceSecondary}>
                  {getWithSpace(Math.ceil(goodDetail.pricePerOne / goodDetail.qty))} ₽ за {goodDetail.measure}
                </Typography>
              )}
            </Box>
            <Box display="flex" alignItems={{ xxs: 'stretch', lg: 'center' }} flexWrap="wrap">
              {goodDetail?.documentWebPath && (
                <Box
                  width={{ xxs: '100%', sm: 'auto' }}
                  mr={{ xxs: 0, sm: 2 }}
                  mb={{ xxs: goodDetail?.videoUrl ? 2 : 0, lg: 0 }}
                >
                  <Button
                    fullWidth={isXS}
                    onClick={handleDownload}
                    startIcon={<Icon path={mdiDownloadOutline} size={1} />}
                    variant="contained"
                    color="secondary"
                  >
                    Скачать технический лист в pdf
                  </Button>
                </Box>
              )}
              {goodDetail?.videoUrl && (
                <a
                  href={goodDetail.videoUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={clsx(classes.link, commonClasses.routerLink)}
                >
                  <Button
                    fullWidth={isXS}
                    startIcon={<Icon path={mdiPlayCircleOutline} size={1} />}
                    variant="contained"
                    color="secondary"
                  >
                    Смотреть обзор
                  </Button>
                </a>
              )}
            </Box>
          </div>
        </Box>
        {goodDetail?.description && (
          <Typography variant={isXS ? 'body2' : 'body1'} className={commonClasses.onSurfacePrimary}>
            {goodDetail.description}
          </Typography>
        )}
      </Container>
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  imgWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 196,
    height: 196,
    minWidth: 196,
    minHeight: 196,
    borderRadius: 8,
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      width: 250,
      height: 250,
      minWidth: 250,
      minHeight: 250,
      margin: theme.spacing(0, 'auto', 2),
    },
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  link: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default SystemCompositionDetailScreen;

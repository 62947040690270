import { Container, makeStyles, Theme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { useAllMQ } from 'hooks/useAllMQ';
import { LocationModel } from 'modules/location/models/LocationModel';
import { routes } from 'screens/routes';

import CloseWidgetConfirmModal from './Header/CloseWidgetConfirmModal';
import DesktopHeader from './Header/DesktopHeader';
import LocationConfirmModal from './Header/LocationConfirmModal';
import MobileHeader from './Header/MobileHeader';
import Location from './Location';
import QMenu from './UI/QMenu';

const Header: React.FC = observer(() => {
  const { locationStore, breadcrumbsStore, orderStore, settingsStore } = useRootStore();
  const { breadcrumbs } = breadcrumbsStore;

  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentLocation, setCurrentLocation] = useState<LocationModel | null>(null);

  const classes = useStyles();
  const { isXS } = useAllMQ();

  const pathnameArray = location.pathname.split('/');

  const isSystemsScreen = pathnameArray.includes('systems');
  const isSystemComposition = pathnameArray.includes('system-composition');
  const isHelpToChoose = pathnameArray.includes('help-to-choose');
  const isSubCategoriesDetail = pathnameArray.includes('detail') && pathnameArray.includes('sub-categories');
  const isCartScreen = pathnameArray.includes('cart');
  const isOtherScreens = isSystemComposition || isHelpToChoose || isSubCategoriesDetail || isCartScreen;

  const getTitle = () => {
    if (isHelpToChoose) {
      return 'Помощь в выборе';
    }
    if (isSystemComposition) {
      return isXS ? 'Назад' : 'Назад к системе звукоизоляции';
    }
    if (isSubCategoriesDetail) {
      return isXS ? 'Назад' : 'Назад к типам конструкции';
    }
    if (isCartScreen) {
      return 'Итоговый расчет';
    }
  };

  // Handlers
  const handleGoBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    history.goBack();
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseConfirmModal = () => {
    locationStore.setConfirmModal(false);
  };

  const handleOpenLocationConfirmModal = (e: React.MouseEvent<HTMLButtonElement>, location: LocationModel) => {
    e.preventDefault();

    locationStore.setConfirmModal(true);
    setCurrentLocation(location);
    setAnchorEl(null);
  };

  const handleChangeLocation = () => {
    if (currentLocation) {
      locationStore.setCurrentLocation(currentLocation);
      locationStore.setConfirmModal(false);
      orderStore.setCartRequestState(null);
      history.push(routes.CategoriesScreen.path);
    }
  };

  const handleWidgetCloseModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isSystemsScreen || isCartScreen) {
      settingsStore.setCloseConfirmModal(true);
    } else {
      handleCloseWidget();
    }
  };

  const handleCloseWidgetConfirmModal = () => {
    settingsStore.setCloseConfirmModal(false);
  };

  const handleCloseWidget = () => {
    if (window.parent) {
      window.parent.postMessage({ type: 'closeTechnosonusWidget' }, '*');
    }
  };

  // Renders
  return (
    <header className={classes.header}>
      <Container maxWidth="lg">
        {isXS ? (
          <MobileHeader
            title={getTitle()}
            breadcrumbs={breadcrumbs}
            isCartScreen={isCartScreen}
            isOtherScreens={isOtherScreens}
            currentLocation={locationStore.currentLocation}
            cartItemCount={orderStore.cartRequestState?.order.length}
            onHandleGoBack={handleGoBack}
            onCloseWidget={handleWidgetCloseModal}
            onHandleOpenMenu={handleOpenMenu}
          />
        ) : (
          <DesktopHeader
            title={getTitle()}
            breadcrumbs={breadcrumbs}
            isCartScreen={isCartScreen}
            isOtherScreens={isOtherScreens}
            currentLocation={locationStore.currentLocation}
            cartItemCount={orderStore.cartRequestState?.order.length}
            onHandleGoBack={handleGoBack}
            onCloseWidget={handleWidgetCloseModal}
            onHandleOpenMenu={handleOpenMenu}
          />
        )}

        <QMenu id="location-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
          <Location onHandleChooseLocation={handleOpenLocationConfirmModal} onClose={handleCloseMenu} />
        </QMenu>

        <LocationConfirmModal
          open={locationStore.confirmModal}
          onCloseModal={handleCloseConfirmModal}
          onHandleChangeLocation={handleChangeLocation}
        />

        <CloseWidgetConfirmModal
          open={settingsStore.closeConfirmModal}
          onCloseModal={handleCloseWidgetConfirmModal}
          onCloseWidget={handleCloseWidget}
        />
      </Container>
    </header>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: '11.5px 0',
    background: '#fff',
    borderBottom: `1px solid ${theme.palette.outline.main}`,
  },
  locationBtn: {
    textAlign: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cartBtn: {
    padding: '0',
    marginRight: theme.spacing(1),
  },
  itemsCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 16,
    height: 16,
    ...theme.typography.overline,
    color: theme.palette.onPrimary.primary,
    background: theme.palette.onSurface.tertiary,
    borderRadius: '50%',
    padding: 2,
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 150,
    ...theme.typography.body1,
  },
}));

export default Header;

import AbstractDto from 'base/AbstractDto';
import { Nullable } from 'base/types/BaseTypes';

import { SortValues } from '../types/SystemsTypes';

export class SystemDto extends AbstractDto {
  type: Nullable<number> = null;
  cat: Nullable<number> = null;
  area: number = 0;
  region: Nullable<number> = null;
  priceSort?: SortValues;
  thicknessSort?: SortValues;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

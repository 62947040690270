import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { mdiMapMarkerOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { appConfig } from 'appConfig';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { LocationModel } from 'modules/location/models/LocationModel';
import { useCommonStyles } from 'styles/commonStyles';
import { useCustomTypography } from 'styles/customTypography';

import QTextField from './UI/QTextField';
import TextButton from './UI/TextButton';

interface ILocationProps {
  onClose?: () => void;
  onHandleChooseLocation?: (e: React.MouseEvent<HTMLButtonElement>, location: LocationModel) => void;
}

const Location: React.FC<ILocationProps> = observer(({ onClose, onHandleChooseLocation }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();
  const { locationStore, settingsStore } = useRootStore();

  const [search, setSearch] = useState('');
  const firstLocationIds = useMemo(
    () => [...appConfig.firstLocations, settingsStore.regionId || 0],
    [settingsStore.regionId],
  );

  const filteredLocationList = useMemo(() => {
    const locations = locationStore.locationList
      .map(location => {
        const currentId = firstLocationIds.indexOf(location.id as number);

        if (currentId !== -1) {
          return new LocationModel({ ...location, bold: true, order: currentId });
        }

        return location;
      })
      .sort((a, b) => a.order - b.order)
      .filter(location => location.title?.toLowerCase().indexOf(search.toLowerCase()) !== -1);

    return locations;
  }, [firstLocationIds, locationStore.locationList, search]);

  // Effects
  useEffect(() => {
    if (!locationStore.locationList.length) {
      locationStore.getLocationList();
    }
  }, [locationStore]);

  useEffect(() => {
    return () => {
      setSearch('');
    };
  }, []);

  // Handlers
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const handleChooseLocation = (e: React.MouseEvent<HTMLButtonElement>, location: LocationModel) => {
    e.preventDefault();

    locationStore.setCurrentLocation(location);
    onClose && onClose();
  };

  const chooseLocation = (e: React.MouseEvent<HTMLButtonElement>, location: LocationModel) => {
    onHandleChooseLocation ? onHandleChooseLocation(e, location) : handleChooseLocation(e, location);
    setSearch('');
  };

  // Renders
  return (
    <div className={classes.paper}>
      <Box mb={3}>
        <Typography className={typography.headline6Strong}>Выберите регион</Typography>
      </Box>
      <Box mb={3}>
        <QTextField
          value={search}
          placeholder="Введите ваш регион"
          startIconProps={{ icon: <Icon path={mdiMapMarkerOutline} size={1} /> }}
          onChange={handleSearch}
        />
      </Box>
      <ul>
        {filteredLocationList.map(location => (
          <li
            className={clsx(classes.item, { active: locationStore.currentLocation?.id === location.id })}
            key={location.id}
          >
            <TextButton
              className={classes.itemBtn}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => chooseLocation(e, location)}
            >
              <Typography className={clsx(commonClasses.onSurfacePrimary)} variant="body2">
                {location.bold ? <b>{location.title}</b> : location.title}
              </Typography>
            </TextButton>
          </li>
        ))}
      </ul>
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    maxWidth: 400,
    padding: theme.spacing(3),
    background: theme.palette.surface.main,
    borderRadius: 8,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 1),
    },
  },
  item: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2.5),
    },
    '&.active': {
      '& $locationTitle': {
        color: theme.palette.primary.main,
      },
    },
  },
  itemBtn: {
    padding: 0,
    textAlign: 'inherit',
  },
}));

export default Location;

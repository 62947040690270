import { Box, BoxProps, SvgIcon, Theme, Typography } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';
import { makeStyles } from '@material-ui/styles';

import { useCommonStyles } from 'styles/commonStyles';

interface IInfoTag extends BoxProps {
  icon: React.ElementType;
  tagTitle: string;
}

const InfoTag: React.FC<IInfoTag> = props => {
  const { icon, tagTitle, ...rest } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <Box {...rest} className={classes.tagItem}>
      <>
        <div className={classes.iconWrap}>
          <SvgIcon viewBox="0 0 20 20" component={icon} />
        </div>
        <Typography className={commonClasses.onSurfacePrimary} variant="subtitle2">
          {tagTitle}
        </Typography>
      </>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  tagItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 2, 0.5, 1.5),
    border: `1px solid ${theme.palette.outline.main}`,
    borderRadius: 4,
    transition: transitions.create('background'),
    '&:hover': {
      backgroundColor: theme.palette.overlay.main,
    },
  },
  iconWrap: {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  icon: {
    width: '100%',
    height: '100%',
  },
}));

export default InfoTag;

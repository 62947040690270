import AbstractApiRepository from 'base/api/AbstractApiRepository';

export default class CategoriesApiRepository extends AbstractApiRepository {
  getCategories = () => {
    return this.apiClient.get({ url: '/cats' });
  };

  getCategoryById = (categoryId: number) => {
    return this.apiClient.get({ url: `/cats/${categoryId}` });
  };
}

import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useAllMQ } from 'hooks/useAllMQ';
import { useCommonStyles } from 'styles/commonStyles';
import { useCustomTypography } from 'styles/customTypography';

interface ITableHeadProps {}

const TableHead: React.FC<ITableHeadProps> = () => {
  const { isSM } = useAllMQ();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  if (isSM) {
    return (
      <Box mb={{ xxs: 1.5, sm: 3 }}>
        <Typography className={clsx(commonClasses.onSurfacePrimary, typography.headline6Strong)}>
          Состав системы
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs className={clsx(classes.col, classes.colBig)}>
          <Typography variant="h5" className={commonClasses.onSurfacePrimary}>
            Состав системы
          </Typography>
        </Grid>
        <Grid item xs className={clsx(classes.colSmall, classes.col)}>
          <Typography variant="body1" className={commonClasses.onSurfaceSecondary}>
            Цена
          </Typography>
        </Grid>
        <Grid item xs className={clsx(classes.colSmall, classes.col)}>
          <Typography variant="body1" className={commonClasses.onSurfaceSecondary}>
            Количество
          </Typography>
        </Grid>
        <Grid item xs className={clsx(classes.colSmall, classes.col)}>
          <Typography variant="body1" className={commonClasses.onSurfaceSecondary} align="right">
            Сумма
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  col: {
    '&:last-child': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  colBig: {
    flexBasis: '58.12%',
    maxWidth: '58.12%',
    [theme.breakpoints.down('md')]: {
      flexBasis: '50%',
      maxWidth: '50%',
    },
  },
  colSmall: {
    flexBasis: '13.96%',
    maxWidth: '13.96%',
    [theme.breakpoints.down('md')]: {
      flexBasis: '16.666%',
      maxWidth: '16.666%',
    },
  },
}));

export default TableHead;

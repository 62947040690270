import { makeAutoObservable, runInAction } from 'mobx';

import { rootStore } from 'base/RootStore';

import TypesService from './TypesService';
import { TypesModel } from './models/TypesModel';

export class TypesStore {
  loading: boolean = false;

  private typesService: TypesService;

  typesList: TypesModel[] = [];
  activeTypesId: number[] = [];

  constructor() {
    makeAutoObservable(this);
    this.typesService = new TypesService();
  }

  getTypes = (categoryId: number) => {
    this.setLoading(true);

    return this.typesService
      .getTypes(categoryId)
      .then(typesList => {
        runInAction(() => {
          if (typesList.length) {
            const typeItem = typesList.find(type => type.id === 2) || typesList[0];
            this.typesList = typesList;
            rootStore.systemsStore.setType(typeItem.id);
            this.setActiveTypesId(typeItem.id as number);
          }
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  resetTypes = () => {
    this.typesList = [];
  };

  resetActiveTypes = () => {
    this.activeTypesId = [];
  };

  setActiveTypesId = (id: number) => {
    this.activeTypesId.push(id);
  };

  removeActiveTypesId = (id: number) => {
    this.activeTypesId = this.activeTypesId.filter(oldId => oldId !== id);
    rootStore.systemsStore.removeSystemFromList(id);
  };
}

import { saveAs } from 'file-saver';
import { makeAutoObservable, runInAction } from 'mobx';

import { rootStore } from 'base/RootStore';
import { Nullable } from 'base/types/BaseTypes';
import NotificationUtil from 'base/utils/NotificationUtil';
import { addRussianCountryPhoneCode } from 'base/utils/addRussianCountryPhoneCode';

import OrderService from './OrderService';
import { OrderCalcModel } from './models/OrderCalcModel';
import {
  IOrderCalcValues,
  IOrderCreateValues,
  IOrderPersonalValues,
  ISerializedOrderCalc,
  ISystemParams,
} from './types/OrderTypes';

const initialValues = {
  fullname: '',
  phone: '',
  email: '',
  description: '',
  agentId: '',
};

export class OrderStore {
  loading: boolean = false;
  orderLoading: boolean = false;
  isAddedCartLoading: boolean = false;

  orderCreateModal: boolean = false;

  values: IOrderPersonalValues = initialValues;
  isPersonalDataScreeen: boolean = false;

  systemParams: Nullable<ISystemParams> = null;
  areaForCalc: number = 1;

  isAddedCart: boolean = false;
  cartRequestState: Nullable<IOrderCalcValues> = null;
  cartInfo: Nullable<OrderCalcModel> = null;

  orderCalcInfo: Nullable<OrderCalcModel> = null;
  area: string = '';
  areaError: boolean = false;

  private orderService: OrderService;

  constructor() {
    makeAutoObservable(this);
    this.orderService = new OrderService();
  }

  get orderCalcInfoObject() {
    return this.orderService.getOrderCalcInfoObject(this.orderCalcInfo);
  }

  get orderValues() {
    const region = rootStore.locationStore.currentLocation?.id ?? 0;
    const goods = rootStore.goodsStore.goodsListIds;
    const system = this.systemParams?.systemId ?? 0;
    const systemPath = this.systemParams?.systemPath ?? '';

    const order: ISerializedOrderCalc[] = [{ area: Number(this.area), goods, system, systemPath }];
    const values: IOrderCalcValues = { region, order, agentId: this.values.agentId };

    return values;
  }

  get orderCreateValues() {
    const region = rootStore.locationStore.currentLocation?.id ?? 0;
    const order =
      this.cartInfo?.order.map(({ system, area, goods }) => ({
        system: system?.id ?? null,
        area,
        goods: goods.map(good => ({ good: good.goods.id, qty: good.qtyTotal })),
      })) ?? [];

    return { order, region };
  }

  get calculatedGoodsMap() {
    return new Map(this.orderCalcInfo?.order[0].goods.map(calcGood => [calcGood.goods.id, calcGood]));
  }

  getCalculatedGood = (goodId?: number | null) => {
    return goodId ? this.calculatedGoodsMap.get(goodId) : null;
  };

  orderCalc = async (values: IOrderCalcValues, isAddCart?: boolean, isCartPageRequest?: boolean) => {
    if (!this.area.length && !isCartPageRequest) {
      this.setAreaError(true);
      return;
    }

    if (this.area.length && !isCartPageRequest) {
      if (!isAddCart) {
        this.setLoading(true);
      } else {
        this.setIsAddedCartLoading(true);
      }

      if (this.areaError) {
        this.setAreaError(false);
      }
    }

    if (isCartPageRequest) {
      this.setLoading(true);
    }

    return this.orderService
      .orderCalc(values)
      .then(orderCalcInfo => {
        if (!isCartPageRequest) {
          runInAction(() => {
            this.areaForCalc = Number(this.area);
            this.orderCalcInfo = orderCalcInfo;
          });

          return orderCalcInfo;
        }

        this.setCartInfo(orderCalcInfo);
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
        this.setIsAddedCartLoading(false);
      });
  };

  orderCreate = (values: IOrderPersonalValues) => {
    this.setOrderLoading(true);

    const data: IOrderCreateValues = {
      ...values,
      isReport: true,
      ...this.orderCreateValues,
      phone: addRussianCountryPhoneCode(values.phone),
    };

    this.orderService
      .orderCreate(data)
      .then(order => {
        if (order.length && order[0].pdfWebPath) {
          saveAs(order[0].pdfWebPath);
          NotificationUtil.showSuccess('Расчет скачивается!');
        }

        this.setOrderCreateModal(true);
        this.setValues({ ...initialValues, agentId: this.values.agentId });
      })
      .catch(() => {})
      .finally(() => {
        this.setOrderLoading(false);
      });
  };

  addCart = async () => {
    const orderCalcInfo = await this.orderCalc(this.orderValues, true);

    if (!orderCalcInfo) {
      return;
    }

    this.setIsAddedCart(true);

    if (!this.cartRequestState) {
      this.setCartRequestState(this.orderValues);
    } else {
      this.setCartRequestState({
        ...this.cartRequestState,
        order: [...this.cartRequestState.order, ...this.orderValues.order],
      });
    }
  };

  setCartRequestState = (cartRequestState: Nullable<IOrderCalcValues>) => {
    this.cartRequestState = cartRequestState;
  };

  setCartInfo = (cartInfo: OrderCalcModel) => {
    this.cartInfo = cartInfo;
  };

  setSystemParams = (systemParams: Nullable<ISystemParams>) => {
    this.systemParams = systemParams;
  };

  setIsAddedCart = (value: boolean) => {
    this.isAddedCart = value;
  };

  setIsAddedCartLoading = (value: boolean) => {
    this.isAddedCartLoading = value;
  };

  setArea = (value: string) => {
    this.area = value;
  };

  setAreaError = (value: boolean) => {
    this.areaError = value;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setOrderLoading = (value: boolean) => {
    this.orderLoading = value;
  };

  setOrderCreateModal = (value: boolean) => {
    this.orderCreateModal = value;
  };

  setValues = (values: IOrderPersonalValues) => {
    this.values = values;
  };

  setIsPersonalDataScreen = (value: boolean) => {
    this.isPersonalDataScreeen = value;
  };

  getAmount = (coefficient: number) => {
    return this.orderService.getAmount(coefficient, this.areaForCalc);
  };

  getTotalPrice = (pricePerOne: number, amount: number) => {
    return this.orderService.getTotalPrice(pricePerOne, amount);
  };

  resetOrderCalcValues = () => {
    this.areaForCalc = 1;
    this.areaError = false;
    this.isAddedCart = false;
    this.orderCalcInfo = null;
    this.isAddedCartLoading = false;
  };
}

import { alpha, Box, Container, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import Loader from 'components/UI/Loader';
import QIcon from 'components/UI/QIcon';
import { useCommonStyles } from 'styles/commonStyles';
import { useCustomTypography } from 'styles/customTypography';

import SystemsFilters from './components/SystemsFilters';
import SystemsItem from './components/SystemsItem';

import nothingFound from 'assets/icons/nothing-found.svg';

interface ISystemsScreenProps {}

const SystemsScreen: React.FC<ISystemsScreenProps> = observer(() => {
  const { systemsStore, typesStore, locationStore } = useRootStore();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  const loading = locationStore.loading || typesStore.loading || systemsStore.loading;

  // Effects
  useEffect(() => {
    return () => {
      systemsStore.resetValues();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemsStore]);

  // Renders
  const renderSystemList = () => {
    if (!systemsStore.systemsList.length) {
      return (
        <div className={classes.wrap}>
          <div className={classes.iconWrap}>
            <QIcon color="#000" src={nothingFound} width={50} height={50} />
          </div>
          <Typography className={clsx(typography.headline6Strong, commonClasses.onSurfacePrimary)} align="center">
            Простите, по вашему запросу товаров сейчас нет.
          </Typography>
        </div>
      );
    }

    return systemsStore.systemsFixedPriceList.map(item => <SystemsItem key={item.id} data={item} />);
  };

  return (
    <Box pt={3}>
      <Container maxWidth="lg">
        <SystemsFilters />
        {loading ? (
          <Loader minHeight="calc(100vh - 257px)" />
        ) : (
          <Box pt={{ xxs: 2.5, md: 3 }}>{renderSystemList()}</Box>
        )}
      </Container>
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    height: 'calc(100vh - 257px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 100,
    minWidth: 100,
    minHeight: 100,
    borderRadius: '50%',
    overflow: 'hidden',
    marginBottom: theme.spacing(3),
    background: alpha(theme.palette.primary.main, 0.25),
  },
}));

export default SystemsScreen;

import { Box, Typography, Button, DialogProps } from '@material-ui/core';

import QModal from 'components/UI/QModal';
import { useCommonStyles } from 'styles/commonStyles';

interface ILocationConfirmModalProps extends DialogProps {
  onCloseModal: () => void;
  onHandleChangeLocation: () => void;
}

const LocationConfirmModal: React.FC<ILocationConfirmModalProps> = props => {
  const { onCloseModal, onHandleChangeLocation, ...rest } = props;
  const commonClasses = useCommonStyles();

  return (
    <QModal onClose={onCloseModal} {...rest}>
      <Box mb={1}>
        <Typography align="center" variant="body1" className={commonClasses.onSurfacePrimary}>
          Вы точно хотите изменить регион?
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography align="center" variant="body1" className={commonClasses.onSurfaceSecondary}>
          После смены региона текущие расчеты не сохранятся.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box mr={2}>
          <Button onClick={onHandleChangeLocation} variant="contained" color="primary">
            Изменить
          </Button>
        </Box>
        <Button onClick={onCloseModal} variant="contained" color="secondary">
          Отменить
        </Button>
      </Box>
    </QModal>
  );
};

export default LocationConfirmModal;

/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles, Theme } from '@material-ui/core';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { IStringParams } from 'base/types/BaseTypes';
import Loader from 'components/UI/Loader';
import { useAllMQ } from 'hooks/useAllMQ';
import { GoodsStore } from 'modules/goods/GoodsStore';
import { Good } from 'modules/goods/models/Good';
import { GoodTemplate } from 'modules/goods/models/GoodTemplate';
import { IChangeGoodsValues } from 'modules/goods/types/GoodsTypes';
import { IGood } from 'modules/order/types/OrderTypes';

import HideButton from './HideButton';
import SCItemContent from './SCItemContent';
import SCItemContentMobile from './SCItemContentMobile';
import SCSubItem from './SCSubItem';
import ShowButton from './ShowButton';

interface ISCItemProps {
  good: Good;
  calcGood?: IGood | null;
}

const SCItem: React.FC<ISCItemProps> = observer(({ good, calcGood }) => {
  const { locationStore, goodsStore, orderStore } = useRootStore();
  const localGoodsStore = useLocalObservable(() => new GoodsStore());
  const params = useParams<IStringParams>();
  const [showSubItems, setShowSubItems] = useState(false);

  const { isSM, isXS } = useAllMQ();
  const classes = useStyles();

  const amount = useMemo(() => {
    if (!calcGood) {
      return good.coefficient ? orderStore.getAmount(good.coefficient) : 1;
    }

    return calcGood.qtyTotal;
  }, [calcGood, good.coefficient]);

  const totalPrice = useMemo(() => {
    if (!calcGood) {
      return good.pricePerOne ? orderStore.getTotalPrice(good.pricePerOne, Number(amount)) : 1;
    }

    return calcGood.priceTotal;
  }, [calcGood, good.pricePerOne, amount]);

  // Effects
  useEffect(() => {
    if (isXS) {
      goodsStore.setCurrentGood(good);
    }
  }, [good, goodsStore, isXS, localGoodsStore.goodsList]);

  // Handlers
  const handleShowSubItems = () => {
    const values: IChangeGoodsValues = {
      good: good.id as number,
      system: Number(params?.systemId),
      region: Number(locationStore.currentLocation?.id),
    };

    if (isXS) {
      goodsStore.setChooseModal(true);
      goodsStore.setCurrentGood(good);
    } else {
      setShowSubItems(true);
    }

    // isDesktop request
    if (!localGoodsStore.changeGoodsList.length) {
      if (!isXS) {
        localGoodsStore.changeGoods(values);
      }
    }

    // isMobile request
    if (isXS) {
      if (!localGoodsStore.changeGoodsList.length) {
        goodsStore.setChangeGoodsLoading(true);
        localGoodsStore
          .changeGoods(values)
          .then(changeGoodsList => {
            if (changeGoodsList) {
              goodsStore.setCurrentChangeGoodsList(changeGoodsList);
            }
          })
          .catch(() => {})
          .finally(() => {
            goodsStore.setChangeGoodsLoading(false);
          });
      } else {
        goodsStore.setCurrentChangeGoodsList(localGoodsStore.changeGoodsList);
      }
    }
  };

  const handleHideSubItems = () => {
    setShowSubItems(false);
  };

  const handleSelect = (selectedGood: GoodTemplate) => {
    if (selectedGood.good) {
      goodsStore.setGoodsList(good, selectedGood);
      goodsStore.setFlatGoodsList();
    }

    if (orderStore.area.length) {
      orderStore.orderCalc(orderStore.orderValues);
    }
  };

  // Renders
  const renderItems = () => {
    if (isXS) {
      return (
        <SCItemContentMobile good={good} amount={amount} totalPrice={totalPrice}>
          {good.isHasAutoChangeGoods && <ShowButton onHandleShowSubItems={handleShowSubItems} />}
        </SCItemContentMobile>
      );
    }

    if (localGoodsStore.changeGoodsLoading || !showSubItems) {
      return isSM ? (
        <SCItemContentMobile good={good} amount={amount} totalPrice={totalPrice}>
          {good.isHasAutoChangeGoods && <ShowButton show={showSubItems} onHandleShowSubItems={handleShowSubItems} />}
        </SCItemContentMobile>
      ) : (
        <SCItemContent good={good} amount={amount} totalPrice={totalPrice}>
          {good.isHasAutoChangeGoods && <ShowButton show={showSubItems} onHandleShowSubItems={handleShowSubItems} />}
        </SCItemContent>
      );
    }
  };

  const renderSubItems = () => {
    if (localGoodsStore.changeGoodsLoading) {
      return <Loader />;
    }

    if (localGoodsStore.changeGoodsList.length && showSubItems) {
      return (
        <div className={classes.subItemsWrap}>
          {localGoodsStore.changeGoodsList.map(item => {
            if (!item.good) {
              return null;
            }

            const calcGood = orderStore.getCalculatedGood(item.good.id);

            return (
              <SCSubItem
                key={item.good.id}
                good={item.good}
                selectedId={good && good.id}
                onHandleSelect={() => handleSelect(item)}
                calcGood={calcGood}
              />
            );
          })}
        </div>
      );
    }
  };

  return (
    <div className={classes.item}>
      {renderItems()}
      {!isXS && renderSubItems()}
      {!isXS && <HideButton show={showSubItems} onHandleHideSubItems={handleHideSubItems} />}
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1.5),
    },
  },
  subItemsWrap: {
    paddingTop: theme.spacing(1.5),
  },
}));

export default SCItem;

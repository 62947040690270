import AbstractModel from 'base/AbstractModel';
import { Nullable } from 'base/types/BaseTypes';

import { IOrderStatus } from '../types/OrderTypes';

export class OrderModel extends AbstractModel {
  regionDiscount: Nullable<number> = null;
  userDiscount: Nullable<number> = null;
  serviceDiscount: Nullable<number> = null;
  totalSumGoods: Nullable<number> = null;
  totalSumGoodsWithDiscount: Nullable<number> = null;
  totalSumGoodsDiscount: Nullable<number> = null;
  totalSumServices: Nullable<number> = null;
  totalSumServicesWithDiscount: Nullable<number> = null;
  totalSumServicesDiscount: Nullable<number> = null;
  totalArea: Nullable<number> = null;
  totalSum: Nullable<number> = null;
  pdfWebPath: Nullable<string> = null;
  status: Nullable<IOrderStatus> = null;
  customerFIO: Nullable<string> = null;
  customerPhone: Nullable<string> = null;
  customerEmail: Nullable<string> = null;
  fullNumberReport: Nullable<string> = null;
  createdAt: Nullable<string> = null;
  totalWeight: Nullable<string> = null;
  totalVolume: Nullable<string> = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

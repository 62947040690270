import * as accounting from 'accounting-js';

export const getWithSpace = (price: string | number | null, haveDecimal: boolean = false) => {
  if (!price) {
    return price;
  }

  let precision = 0;

  if (haveDecimal) {
    precision = 2;
  }

  return accounting.formatMoney(price, { symbol: '', format: '%v', decimal: '.', thousand: ' ', precision });
};

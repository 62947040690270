import AbstractApiRepository from 'base/api/AbstractApiRepository';

import { ChangeGoodsDto } from './forms/ChangeGoodsDto';

export default class GoodsApiRepository extends AbstractApiRepository {
  getGoods = (systemId: number, region: number | null) => {
    return this.apiClient.post({ url: '/goods', data: { systems: systemId, region } });
  };

  getGoodById = (goodId: number, region: number | null) => {
    const params = region ? `?region=${region}` : '';

    return this.apiClient.get({ url: `/goods/${goodId}${params}` });
  };

  changeGoods = (data: ChangeGoodsDto) => {
    return this.apiClient.post({ url: '/change_good/', data });
  };
}

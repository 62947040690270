import { Box, Container } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import Loader from 'components/UI/Loader';
import { useCommonStyles } from 'styles/commonStyles';

const HelpToChooseScreen: React.FC = observer(() => {
  const { systemsStore } = useRootStore();
  const commonClasses = useCommonStyles();

  // Effects
  useEffect(() => {
    systemsStore.getEntityInfo({ html: true, entity: 'system' });
  }, [systemsStore]);

  // Renders
  if (systemsStore.loading) {
    return <Loader isAbsolute minHeight="100vh" />;
  }

  return (
    <Box pt={3}>
      <Container maxWidth="lg">
        <>
          {systemsStore.entityInfo?.info && (
            <div
              className={commonClasses.content}
              dangerouslySetInnerHTML={{ __html: systemsStore.entityInfo?.info }}
            />
          )}
        </>
      </Container>
    </Box>
  );
});

export default HelpToChooseScreen;

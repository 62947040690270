import { Box, Container, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { makePath } from 'base/routes/utils/makePath';
import { IStringParams } from 'base/types/BaseTypes';
import Loader from 'components/UI/Loader';
import { useAllMQ } from 'hooks/useAllMQ';
import { routes } from 'screens/routes';
import { useCustomTypography } from 'styles/customTypography';

import ReadySolution from './components/ReadySolution';
import SubCategoriesCard from './components/SubCategoriesCard';

import iconWebPath from 'assets/categories/icons/all-solutions-icon.png';

const SubCategoriesScreen: React.FC = observer(() => {
  const { categoriesStore, systemsStore, orderStore } = useRootStore();
  const params = useParams<IStringParams>();

  const { isXS } = useAllMQ();
  const typography = useCustomTypography();

  // Effects
  useEffect(() => {
    systemsStore.setArea('');
    orderStore.setArea('');

    if (params?.categoryId) {
      categoriesStore.getCategoryById(Number(params?.categoryId));
    }

    return () => {
      categoriesStore.resetSubCategory();
    };
  }, [categoriesStore, orderStore, params?.categoryId, systemsStore]);

  if (categoriesStore.loading) {
    return <Loader minHeight="100vh" />;
  }

  return (
    <Box pt={3}>
      <Container maxWidth="lg">
        <Box mb={{ xxs: 2.5, sm: 3 }}>
          <Typography variant={isXS ? 'inherit' : 'h5'} className={clsx({ [typography.headline6Strong]: isXS })}>
            Выберите тип конструкции
          </Typography>
        </Box>
        <Grid container spacing={isXS ? 2 : 3}>
          {categoriesStore.subCategory?.subcats.map(item => {
            return (
              item.id && (
                <Grid key={item.id} item xs={12} sm={6} md={4}>
                  <SubCategoriesCard
                    detailPath={makePath(routes.SubCategoriesDetailScreen.path, [
                      { p: 'categoryId', v: params.categoryId },
                      { p: 'subCategoryId', v: item.id },
                    ])}
                    path={makePath(routes.SystemsScreen.path, [
                      { p: 'categoryId', v: params.categoryId },
                      { p: 'subCategoryId', v: item.id },
                    ])}
                    {...item}
                  />
                </Grid>
              )
            );
          })}
          {categoriesStore.subCategory?.subcats.length ? (
            <Grid item xs={12} sm={6} md={4}>
              <ReadySolution
                path={makePath(routes.SystemsScreen.path, [
                  { p: 'categoryId', v: params.categoryId },
                  { p: 'subCategoryId', v: params.categoryId },
                ])}
                id={categoriesStore.subCategory?.subcats[0].id}
                title="Все готовые решения"
                cat={categoriesStore.subCategory?.subcats[0].cat}
                description="Все готовые решения"
                iconWebPath={iconWebPath}
                previewWebPath={categoriesStore.subCategory.cat?.widgetPreviewWebPath || ''}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Container>
    </Box>
  );
});

export default SubCategoriesScreen;

import { makeAutoObservable } from 'mobx';

import { Breadcrumb } from './models/Breadcrumb';

export class BreadcrumbsStore {
  loading: boolean = false;

  breadcrumbs: Breadcrumb[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
    this.breadcrumbs = breadcrumbs;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };
}

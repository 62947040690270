import TypesApiRepository from './TypesApiRepository';
import TypesFactory from './TypesFactory';
import { TypesModel } from './models/TypesModel';

export default class TypesService {
  typesApi: TypesApiRepository;
  typesFactory: TypesFactory;

  constructor() {
    this.typesApi = new TypesApiRepository();
    this.typesFactory = new TypesFactory();
  }

  getTypes = async (categoryId: number): Promise<TypesModel[]> => {
    const { data } = await this.typesApi.getTypes(categoryId);
    return this.typesFactory.createList<TypesModel>(TypesModel, data.data);
  };
}

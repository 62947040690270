import { Box, Typography, Button, makeStyles, Theme } from '@material-ui/core';
import { mdiContentCopy, mdiDownloadOutline } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import Scrollbars from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import Loader from 'components/UI/Loader';
import { useAllMQ } from 'hooks/useAllMQ';
import { HandleAction } from 'modules/order/types/OrderTypes';
import { routes } from 'screens/routes';
import { useCommonStyles } from 'styles/commonStyles';

import CartItem from './CartItem';
import OrderCard from './OrderCard';

interface IOrderFormProps {
  onHandleOpenModal: (id: number) => void;
  onHandleClick: (action: HandleAction) => void;
}

const OrderForm: React.FC<IOrderFormProps> = observer(props => {
  const { onHandleClick, onHandleOpenModal } = props;
  const { orderStore } = useRootStore();

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { isMD } = useAllMQ();

  const renderContent = () => {
    return (
      <div>
        {orderStore.loading && <Loader isAbsolute minHeight="calc(100vh - 112px)" />}
        <Box mb={3}>
          <Typography variant="h5" className={commonClasses.onSurfacePrimary}>
            Системы звукоизоляции
            <span className={clsx(commonClasses.onSurfaceTertiary, classes.itemsCount)}>
              {orderStore.cartRequestState?.order.length}
            </span>
          </Typography>
        </Box>
        {!!orderStore.cartInfo?.order.length &&
          orderStore.cartInfo.order.map((item, idx) => (
            <Box key={idx} mb={3}>
              <CartItem id={idx} data={item} onHandleDelete={onHandleOpenModal} />
            </Box>
          ))}
        <Box mb={3}>
          <Link className="router-link" to={routes.CategoriesScreen.path}>
            <Button fullWidth className={classes.addBtn}>
              Добавить еще систему
            </Button>
          </Link>
        </Box>
        <Box mb={2}>
          <OrderCard />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap={{ xxs: 'wrap', sm: 'nowrap' }}>
          <div className={classes.btnCol}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              startIcon={<Icon path={mdiDownloadOutline} size={1} />}
              onClick={() => onHandleClick('download')}
            >
              Скачать pdf-расчёт
            </Button>
          </div>
          <div className={classes.btnCol}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              startIcon={<Icon path={mdiContentCopy} size={1} />}
              onClick={() => onHandleClick('copy')}
            >
              Копировать ссылку
            </Button>
          </div>
        </Box>
      </div>
    );
  };

  // Renders
  return isMD ? (
    renderContent()
  ) : (
    <Box mr={-2}>
      <Scrollbars autoHide autoHeight autoHeightMax="calc(100vh - 113px)">
        <Box pr={2}>{renderContent()}</Box>
      </Scrollbars>
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  btnCol: {
    flex: '1 1 calc(50% - 4px)',
    maxWidth: 'calc(50% - 4px)',
    [theme.breakpoints.down('xs')]: {
      flex: '1 1 100%',
      maxWidth: '100%',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
  },
  addBtn: {
    border: `1px dashed ${theme.palette.outline.main}`,
    color: theme.palette.primary.main,
  },
  itemsCount: {
    marginLeft: theme.spacing(1),
  },
}));

export default OrderForm;

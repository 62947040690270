import { Grid, Box, Typography, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { Link, useParams } from 'react-router-dom';

import { makePath } from 'base/routes/utils/makePath';
import { IStringParams, Nullable } from 'base/types/BaseTypes';
import TextButton from 'components/UI/TextButton';
import { getWithSpace } from 'helpers/getWithSpace';
import { useAllMQ } from 'hooks/useAllMQ';
import { Good } from 'modules/goods/models/Good';
import { routes } from 'screens/routes';
import { useCommonStyles } from 'styles/commonStyles';

import discount from 'assets/icons/discount.png';

export interface ISCItemContentProps {
  good: Good;
  amount: Nullable<number>;
  totalPrice: Nullable<number>;
}

const SCItemContent: React.FC<PropsWithChildren<ISCItemContentProps>> = props => {
  const { good, amount, totalPrice, children } = props;
  const { isMD } = useAllMQ();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const params = useParams<IStringParams>();

  // Renders
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs className={clsx(classes.colBig, classes.col)}>
        <Box display="flex" alignItems="center" mb={{ xxs: 1, md: 0 }}>
          {!!good.imagesWebPath?.length && (
            <div className={commonClasses.imgWrap}>
              {good.isAction && (
                <img className={commonClasses.imgAction} width={24} height={24} src={discount} alt="" />
              )}
              <img className={commonClasses.img} src={good.imagesWebPath[0]} alt="" />
            </div>
          )}
          <div>
            <Box mb={1}>
              <Link
                to={makePath(routes.SystemCompositionDetailScreen.path, [
                  { p: 'categoryId', v: params.categoryId },
                  { p: 'subCategoryId', v: params.subCategoryId },
                  { p: 'systemId', v: params.systemId },
                  { p: 'systemCompositionId', v: good.id ?? '' },
                ])}
                className={clsx('router-link', classes.link)}
              >
                <TextButton color="primary" className={classes.itemTitle}>
                  <Typography variant={isMD ? 'body2' : 'body1'}>{good.title}</Typography>
                </TextButton>
              </Link>
            </Box>
            {children}
          </div>
        </Box>
      </Grid>
      <Grid item xs className={clsx(classes.colSmall, classes.col)}>
        <Typography variant="h6" className={commonClasses.onSurfacePrimary}>
          {getWithSpace(good.pricePerOne)} ₽
        </Typography>
      </Grid>
      <Grid item xs className={clsx(classes.colSmall, classes.col)}>
        <Typography variant="h6" className={commonClasses.onSurfacePrimary}>
          {getWithSpace(amount)} шт.
        </Typography>
      </Grid>
      <Grid item xs className={clsx(classes.colSmall, classes.col)}>
        <Typography noWrap variant="h6" className={commonClasses.onSurfacePrimary}>
          {getWithSpace(totalPrice)} ₽
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  itemTitle: {
    textAlign: 'inherit',
    padding: '0',
  },
  link: {
    display: 'inline-block',
  },
  col: {
    [theme.breakpoints.up('md')]: {
      '&:last-child': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      },
    },
  },
  colBig: {
    flexBasis: '58.12%',
    maxWidth: '58.12%',
    [theme.breakpoints.down('md')]: {
      flexBasis: '50%',
      maxWidth: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  colSmall: {
    flexBasis: '13.96%',
    maxWidth: '13.96%',
    [theme.breakpoints.down('md')]: {
      flexBasis: '16.666%',
      maxWidth: '16.666%',
    },
  },
}));

export default SCItemContent;

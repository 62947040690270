import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import { mdiCheck } from '@mdi/js';
import { Icon } from '@mdi/react';

interface IChooseBtnProps extends ButtonProps {
  isSelected?: boolean;
}

const ChooseBtn: React.FC<IChooseBtnProps> = ({ isSelected, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      {...rest}
      color="primary"
      variant="contained"
      disabled={isSelected}
      className={classes.chooseBtn}
      endIcon={isSelected && <Icon path={mdiCheck} size={1} />}
      size="small"
    >
      {isSelected ? 'Выбрано' : 'Выбрать'}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  chooseBtn: {
    marginBottom: theme.spacing(1),
    '&:disabled': {
      background: theme.palette.primary.main,
      opacity: 0.48,
      color: theme.palette.onPrimary.primary,
      '& .MuiButton-endIcon': {
        color: theme.palette.onPrimary.secondary,
      },
    },
  },
}));

export default ChooseBtn;

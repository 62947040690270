import { makeStyles, Theme } from '@material-ui/core';

export const useCommonStyles = makeStyles((theme: Theme) => ({
  routerLink: {
    textDecoration: 'none',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  onSurfacePrimary: {
    color: theme.palette.onSurface.primary,
  },
  onSurfaceSecondary: {
    color: theme.palette.onSurface.secondary,
  },
  onSurfaceTertiary: {
    color: theme.palette.onSurface.tertiary,
  },
  onPrimarySecondary: {
    color: theme.palette.onPrimary.secondary,
  },
  surfaceMain: {
    color: theme.palette.surface.main,
  },
  content: {
    '& h5': {
      ...theme.typography.h5,
    },
    '& h6': {
      ...theme.typography.h6,
      '& strong, & b': {
        fontWeight: 500,
      },
    },
    '& p': {
      ...theme.typography.body1,
    },
    '& ul': {
      paddingInlineStart: 40,
      listStyleType: 'disc',
    },
    '& ol': {
      paddingInlineStart: 40,
      listStyleType: 'inherit',
    },
    '& .marker': {
      background: 'yellow',
    },
    '& img': {
      maxWidth: '100%',
      height: 'auto !important',
    },
  },
  cursorDefault: {
    cursor: 'default',
  },

  imgWrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    minWidth: 60,
    minHeight: 60,
    marginRight: theme.spacing(2),
  },
  imgAction: {
    position: 'absolute',
    top: theme.spacing(-1),
    left: theme.spacing(-1),
    zIndex: 1,
  },
  img: {
    overflow: 'hidden',
    borderRadius: 8,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

import React from 'react';

import { BreadcrumbsStore } from 'modules/breadcrumbs/BreadcrumbsStore';
import { CategoriesStore } from 'modules/categories/CategoriesStore';
import { GoodsStore } from 'modules/goods/GoodsStore';
import { LocationStore } from 'modules/location/LocationStore';
import { OrderStore } from 'modules/order/OrderStore';
import { SettingsStore } from 'modules/settings/SettingsStore';
import { SystemsStore } from 'modules/systems/SystemsStore';
import { TypesStore } from 'modules/types/TypesStore';

class RootStore {
  goodsStore: GoodsStore;
  typesStore: TypesStore;
  orderStore: OrderStore;
  systemsStore: SystemsStore;
  settingsStore: SettingsStore;
  locationStore: LocationStore;
  categoriesStore: CategoriesStore;
  breadcrumbsStore: BreadcrumbsStore;

  constructor() {
    this.goodsStore = new GoodsStore();
    this.typesStore = new TypesStore();
    this.orderStore = new OrderStore();
    this.systemsStore = new SystemsStore();
    this.settingsStore = new SettingsStore();
    this.locationStore = new LocationStore();
    this.categoriesStore = new CategoriesStore();
    this.breadcrumbsStore = new BreadcrumbsStore();
  }
}

export const rootStore = new RootStore();

export const storesContext = React.createContext(rootStore);

import { makeAutoObservable, runInAction } from 'mobx';

import { rootStore } from 'base/RootStore';
import { Nullable } from 'base/types/BaseTypes';

import GoodsService from './GoodsService';
import { Good } from './models/Good';
import { GoodTemplate } from './models/GoodTemplate';
import { IChangeGoodsValues } from './types/GoodsTypes';

export class GoodsStore {
  loading: boolean = false;
  changeGoodsLoading: boolean = false;

  goodsList: GoodTemplate[] = [];
  goodDetail: Nullable<Good> = null;
  goodsListFlat: Good[] = [];

  changeGoodsList: GoodTemplate[] = [];
  currentChangeGoodsList: GoodTemplate[] = [];
  currentGood: Nullable<Good> = null;
  chooseModal: boolean = false;

  private goodsService: GoodsService;

  constructor() {
    makeAutoObservable(this);
    this.goodsService = new GoodsService();
  }

  get goodsListIds() {
    return this.goodsListFlat.map(good => ({ good: good.id }));
  }

  get getBasePrice() {
    let basePrice = 0;

    this.goodsListFlat.forEach((item: Good) => {
      if (item?.pricePerOne) {
        basePrice += item.pricePerOne * Math.ceil(item.coefficient ?? 1);
      }
    });

    return basePrice;
  }

  getGoods = (systemId: number) => {
    this.setLoading(true);
    const region = rootStore.locationStore.currentLocation?.id ?? null;

    this.goodsService
      .getGoods(systemId, region)
      .then(goodsList => {
        runInAction(() => {
          this.goodsList = goodsList;
          this.setFlatGoodsList();
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  getGoodById = (goodId: number) => {
    this.setLoading(true);
    const region = rootStore.locationStore.currentLocation?.id ?? null;

    this.goodsService
      .getGoodById(goodId, region)
      .then(goodDetailList => {
        runInAction(() => {
          if (goodDetailList.length) {
            const [goodDetail] = goodDetailList;
            this.goodDetail = goodDetail;
          }
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  changeGoods = (values: IChangeGoodsValues) => {
    this.setChangeGoodsLoading(true);
    const region = rootStore.locationStore.currentLocation?.id ?? null;

    return this.goodsService
      .changeGoods({ ...values, region })
      .then(changeGoodsList => {
        runInAction(() => {
          this.changeGoodsList = changeGoodsList;
        });

        return changeGoodsList;
      })
      .catch(() => {})
      .finally(() => {
        this.setChangeGoodsLoading(false);
      });
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setChangeGoodsLoading = (changeGoodLoading: boolean) => {
    this.changeGoodsLoading = changeGoodLoading;
  };

  setGoodsList = (currentGood: Good, selectedGood: GoodTemplate) => {
    this.goodsList = this.goodsService.getSlicedGoods(this.goodsList, currentGood, selectedGood);
  };

  setCurrentChangeGoodsList = (currentChangeGoodsList: GoodTemplate[]) => {
    this.currentChangeGoodsList = currentChangeGoodsList;
  };

  setCurrentGood = (good: Nullable<Good>) => {
    this.currentGood = good;
  };

  setChooseModal = (value: boolean) => {
    this.chooseModal = value;
  };

  resetGoodDetail = () => {
    this.goodDetail = null;
  };

  setFlatGoodsList = () => {
    const flatGoodsList = this.goodsService.getFlatGoodsList(this.goodsList);
    this.goodsListFlat = flatGoodsList;
  };
}

import { Box, makeStyles, SvgIcon, Theme, Typography } from '@material-ui/core';

import { useAllMQ } from 'hooks/useAllMQ';
import { useCommonStyles } from 'styles/commonStyles';

interface ITagItemProps {
  icon: React.ElementType;
  title: string;
  value: string;
}

const TagItem: React.FC<ITagItemProps> = props => {
  const { icon, title, value } = props;
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { isXS } = useAllMQ();

  return (
    <Box display="flex">
      <div className={classes.iconWrap}>
        <SvgIcon color="primary" viewBox="0 0 20 20" component={icon} />
      </div>
      <div>
        <Box mb={0.5}>
          <Typography className={commonClasses.onSurfaceSecondary} variant="body2">
            {title}
          </Typography>
        </Box>
        <Typography className={commonClasses.onSurfacePrimary} variant={isXS ? 'body1' : 'body2'}>
          {value}
        </Typography>
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  iconWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    marginRight: theme.spacing(2),
    '& > svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

export default TagItem;

import { Box, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { mdiMapMarkerOutline, mdiClose, mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { Nullable } from 'base/types/BaseTypes';
import QBreadcrumbs from 'components/UI/QBreadcrumbs';
import TextButton from 'components/UI/TextButton';
import { Breadcrumb } from 'modules/breadcrumbs/models/Breadcrumb';
import { LocationModel } from 'modules/location/models/LocationModel';
import { routes } from 'screens/routes';
import { useCommonStyles } from 'styles/commonStyles';

interface IDesktopHeaderProps {
  title?: string;
  isCartScreen: boolean;
  isOtherScreens: boolean;
  breadcrumbs: Breadcrumb[];
  cartItemCount?: number;
  currentLocation: Nullable<LocationModel>;
  onHandleOpenMenu: (e: React.MouseEvent<HTMLElement>) => void;
  onCloseWidget: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onHandleGoBack: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const DesktopHeader: React.FC<IDesktopHeaderProps> = observer(props => {
  const {
    title,
    breadcrumbs,
    isCartScreen,
    isOtherScreens,
    currentLocation,
    cartItemCount,
    onHandleOpenMenu,
    onCloseWidget,
    onHandleGoBack,
  } = props;

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  // Renders
  const renderLeftColumn = () => {
    if (isOtherScreens) {
      return (
        <Box display="flex" alignItems="center">
          <Box mr={{ xxs: 1, md: 2 }}>
            <IconButton onClick={onHandleGoBack} className={commonClasses.onSurfacePrimary}>
              <Icon path={mdiArrowLeft} size={1} />
            </IconButton>
          </Box>
          <Typography className={commonClasses.onSurfacePrimary} variant="h6" noWrap>
            {title}
          </Typography>
        </Box>
      );
    }

    if (breadcrumbs.length === 0) {
      return (
        <Typography variant="h6" className={commonClasses.onSurfacePrimary}>
          Калькулятор готовых решений звукоизоляции
        </Typography>
      );
    }

    return (
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <IconButton onClick={onHandleGoBack} className={commonClasses.onSurfacePrimary}>
            <Icon path={mdiArrowLeft} size={1} />
          </IconButton>
        </Box>
        {<QBreadcrumbs breadcrumbs={breadcrumbs} />}
      </Box>
    );
  };

  const renderRightColumn = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {!!cartItemCount && !isCartScreen && (
          <Link className="router-link" to={routes.CartScreen.path}>
            <Box display="flex" alignItems="center">
              <TextButton color="primary" className={classes.cartBtn}>
                <Typography variant="body1">Моя заявка</Typography>
              </TextButton>
              <div className={classes.itemsCount}>{cartItemCount}</div>
            </Box>
          </Link>
        )}
        {currentLocation && (
          <Box mr={1}>
            <TextButton
              color="primary"
              startIcon={<Icon path={mdiMapMarkerOutline} size={1} />}
              onClick={onHandleOpenMenu}
              className={classes.locationBtn}
            >
              <div className={classes.textEllipsis}>{currentLocation?.title}</div>
            </TextButton>
          </Box>
        )}
        <IconButton onClick={onCloseWidget} className={commonClasses.onSurfaceSecondary}>
          <Icon path={mdiClose} size={1} />
        </IconButton>
      </Box>
    );
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={3} md={5} lg={6}>
        {renderLeftColumn()}
      </Grid>
      <Grid item xs={6}>
        {renderRightColumn()}
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: '11.5px 0',
    background: '#fff',
    borderBottom: `1px solid ${theme.palette.outline.main}`,
  },
  locationBtn: {
    textAlign: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cartBtn: {
    padding: '0',
    marginRight: theme.spacing(1),
  },
  itemsCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 16,
    height: 16,
    ...theme.typography.overline,
    color: theme.palette.onPrimary.primary,
    background: theme.palette.onSurface.tertiary,
    borderRadius: '50%',
    padding: 2,
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 150,
    ...theme.typography.body1,
  },
}));

export default DesktopHeader;

import { RoutesType } from 'base/routes/types/RouteTypes';

import CartScreen from './cart/CartScreen';
import CategoriesScreen from './categories/CategoriesScreen';
import SubCategoriesDetailScreen from './categories/SubCategoriesDetailScreen';
import SubCategoriesScreen from './categories/SubCategoriesScreen';
import HelpToChooseScreen from './help-to-choose/HelpToChooseScreen';
import NotFoundScreen from './not-found/NotFoundScreen';
import SystemCompositionDetailScreen from './system-composition/SystemCompositionDetailScreen';
import SystemDetailScreen from './systems/SystemDetailScreen';
import SystemsScreen from './systems/SystemsScreen';

const screens = {
  CategoriesScreen,
  SubCategoriesScreen,
  SystemsScreen,
  SystemDetailScreen,
  SystemCompositionDetailScreen,
  SubCategoriesDetailScreen,
  CartScreen,
  HelpToChooseScreen,
  NotFoundScreen,
};

type RoutesKeys = keyof typeof screens;
export const routes: RoutesType<RoutesKeys> = {
  CategoriesScreen: {
    path: '/',
    exact: true,
    title: 'Область применения',
    component: screens.CategoriesScreen,
  },
  SubCategoriesScreen: {
    path: '/category/:categoryId/',
    exact: true,
    title: 'Тип конструкции',
    component: screens.SubCategoriesScreen,
  },
  SubCategoriesDetailScreen: {
    path: '/category/:categoryId/sub-categories/:subCategoryId/detail',
    exact: true,
    title: 'Продукты',
    component: screens.SubCategoriesDetailScreen,
  },
  SystemsScreen: {
    path: '/category/:categoryId/sub-categories/:subCategoryId/systems',
    exact: true,
    title: 'Продукты',
    component: screens.SystemsScreen,
  },
  SystemDetailScreen: {
    path: '/category/:categoryId/sub-categories/:subCategoryId/systems/:systemId',
    exact: true,
    title: 'Информация о продукте',
    component: screens.SystemDetailScreen,
  },
  HelpToChooseScreen: {
    path: '/help-to-choose',
    exact: true,
    title: 'Помощь в выборе',
    component: screens.HelpToChooseScreen,
  },
  SystemCompositionDetailScreen: {
    path: '/category/:categoryId/sub-categories/:subCategoryId/systems/:systemId/system-composition/:systemCompositionId',
    exact: true,
    title: 'Информация о продукте',
    component: screens.SystemCompositionDetailScreen,
  },
  CartScreen: {
    path: '/cart',
    exact: true,
    title: 'Корзина',
    component: screens.CartScreen,
  },
  NotFoundScreen: {
    exact: true,
    path: '/not-found',
    title: '404 Страница не найдена',
    component: screens.NotFoundScreen,
  },
};

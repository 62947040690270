import { makeStyles, Theme, Dialog, DialogProps } from '@material-ui/core';
import clsx from 'clsx';

interface IQModalProps extends DialogProps {
  modalClassName?: string;
}

const QModal: React.FC<IQModalProps> = props => {
  const classes = useStyles();
  const { children, className, modalClassName, ...rest } = props;

  const handleClose = (e: any, r: any) => {
    if (props.onClose) {
      props.onClose(e, r);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      PaperProps={{ className: clsx(classes.modal, modalClassName) }}
      scroll="body"
      disableRestoreFocus
      className={clsx(classes.modalWrap, className)}
      {...rest}
    >
      {children}
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  modalWrap: {
    '& .MuiBackdrop-root': {
      background: 'rgba(0, 0, 0, 0.4)',
    },
  },
  modal: {
    padding: `${theme.spacing(3)}px !important`,
    maxWidth: 488,
    minHeight: 136,
    width: '100%',
    outline: 'none',
    borderRadius: 8,
    margin: theme.spacing(4),
    background: theme.palette.surface.main,
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0),
      maxWidth: 'calc(100% - 16px) !important',
      padding: theme.spacing(2, 2, 4),
    },
  },
}));

export default QModal;

import AbstractModel from 'base/AbstractModel';
import { Nullable } from 'base/types/BaseTypes';
import { Category } from 'modules/categories/models/Category';
import { TypesModel } from 'modules/types/models/TypesModel';

export class System extends AbstractModel {
  id: Nullable<number> = null;
  title: Nullable<string> = null;
  videoUrl: Nullable<string> = null;
  description: Nullable<string> = null;
  price: Nullable<number> = null;
  indicatorRW: Nullable<string> = null;
  indicatorThickness: Nullable<number> = null;
  indicatorWeight: Nullable<number> = null;
  indicatorDeltaLNW: Nullable<string> = null;
  indicatorDeltaRw: Nullable<string> = null;
  type: Nullable<TypesModel> = null;
  cat: Nullable<Category> = null;
  imagesWebPath: Nullable<Array<string>> = null;
  documentWebPath: Nullable<string> = null;
  description2: Nullable<string> = null;
  info: Nullable<string> = null;
  isAction: boolean = false;
  sort: Nullable<number> = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

import AbstractDto from 'base/AbstractDto';
import { Nullable } from 'base/types/BaseTypes';

export class Breadcrumb extends AbstractDto {
  path: Nullable<string> = null;
  title: Nullable<string> = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

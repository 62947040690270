import { makeAutoObservable, runInAction } from 'mobx';

import { rootStore } from 'base/RootStore';
import { Nullable } from 'base/types/BaseTypes';

import SystemsService from './SystemsService';
import { EntityModel } from './models/Entity';
import { System } from './models/System';
import { IEntityInfoValues, ISystemsValues } from './types/SystemsTypes';

const initialValues: ISystemsValues = {
  type: null,
  cat: null,
  region: null,
  sort: 'default',
};

export class SystemsStore {
  loading: boolean = false;

  systemsList: System[] = [];
  system: Nullable<System> = null;
  values: ISystemsValues = initialValues;
  area: string = '';
  areaForCalc: number = 1;

  entityInfo: Nullable<EntityModel> = null;

  private systemsService: SystemsService;

  constructor() {
    makeAutoObservable(this);
    this.systemsService = new SystemsService();
  }

  get systemsFixedPriceList() {
    return this.systemsService.getSystemsFixedPriceList(this.systemsList, this.areaForCalc);
  }

  getSystems = (values: ISystemsValues) => {
    const area = this.area.length ? Number(this.area) : 1;
    this.setLoading(true);

    this.systemsService
      .getSystems(values, this.area)
      .then(({ list, breadcrumbs }) => {
        runInAction(() => {
          this.areaForCalc = area;
          this.systemsList = list;
          rootStore.breadcrumbsStore.setBreadcrumbs(breadcrumbs);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  getSystemById = (systemId: number) => {
    this.setLoading(true);

    this.systemsService
      .getSystemById(systemId)
      .then(({ system, breadcrumbs }) => {
        runInAction(() => {
          if (system.length) {
            const [systemData] = system;
            this.system = systemData;
          }
          rootStore.breadcrumbsStore.setBreadcrumbs(breadcrumbs);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  getEntityInfo = (data: IEntityInfoValues) => {
    this.setLoading(true);

    this.systemsService
      .getEntityInfo(data)
      .then(entity => {
        runInAction(() => {
          this.entityInfo = entity;
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  setValues = (values: ISystemsValues) => {
    this.values = values;
  };

  setType = (type: Nullable<number>) => {
    this.values.type = [Number(type)];
  };

  setArea = (area: string) => {
    this.area = area;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  resetValues = () => {
    this.values = initialValues;
    this.systemsList = [];
    this.areaForCalc = 1;
  };

  resetSystem = () => {
    this.system = null;
  };

  removeSystemFromList = (id: number) => {
    this.systemsList = this.systemsList.filter(system => system.type?.id !== id);
  };
}

import { Theme, Typography, makeStyles } from '@material-ui/core';

import { useCommonStyles } from 'styles/commonStyles';

interface IInfoListProps {
  data: string[];
}

const InfoList: React.FC<IInfoListProps> = props => {
  const { data } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <ul>
      {data.map(
        (content, index) =>
          !!content.length && (
            <li className={classes.listItem} key={index}>
              <Typography
                className={commonClasses.onSurfacePrimary}
                dangerouslySetInnerHTML={{ __html: content }}
                variant="body1"
              />
            </li>
          ),
      )}
    </ul>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
}));

export default InfoList;

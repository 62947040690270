import AbstractDto from 'base/AbstractDto';
import { Nullable } from 'base/types/BaseTypes';

import { ISerializedOrderCalc } from '../types/OrderTypes';

export class OrderCalcDto extends AbstractDto {
  region: Nullable<number> = null;
  discount: Nullable<number> = null;
  service_discount: Nullable<number> = null;
  isReport: boolean = false;
  order: ISerializedOrderCalc[] = [];
  agentId: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

import SettingsApiRepository from './SettingsApiRepository';
import SettingsFactory from './SettingsFactory';
import { Settings } from './models/Settings';

export default class SettingsService {
  settingsApi: SettingsApiRepository;
  settingsFactory: SettingsFactory;

  constructor() {
    this.settingsApi = new SettingsApiRepository();
    this.settingsFactory = new SettingsFactory();
  }

  getSettings = async (agentId: string | number): Promise<Settings> => {
    const { data } = await this.settingsApi.getSettings(agentId);
    return this.settingsFactory.create<Settings>(Settings, data.data);
  };
}

import AbstractModel from 'base/AbstractModel';
import { Nullable } from 'base/types/BaseTypes';
import { Category } from 'modules/categories/models/Category';
import { TypesModel } from 'modules/types/models/TypesModel';

import { IGoodsCategory } from '../types/GoodsTypes';

export class Good extends AbstractModel {
  id: Nullable<number> = null;
  title: Nullable<string> = null;
  videoUrl: Nullable<string> = null;
  description: Nullable<string> = null;
  price: Nullable<number> = null;
  pricePerOne: Nullable<number> = null;
  indicatorRW: Nullable<string> = null;
  indicatorThickness: Nullable<number> = null;
  indicatorWeight: Nullable<number> = null;
  indicatorDeltaLNW: Nullable<string> = null;
  indicatorDeltaRw: Nullable<string> = null;
  type: Nullable<TypesModel> = null;
  cat: Nullable<Category> = null;
  imagesWebPath: Nullable<Array<string>> = null;
  documentWebPath: Nullable<string> = null;
  isAction: boolean = false;
  sort: Nullable<number> = null;
  articul: Nullable<string> = null;
  sizeX: Nullable<number> = null;
  sizeY: Nullable<number> = null;
  sizeZ: Nullable<number> = null;
  systems: Nullable<any[]> = null;
  goodsCat: Nullable<IGoodsCategory> = null;
  weight: Nullable<number> = null;
  volume: Nullable<number> = null;
  measure: Nullable<string> = null;
  qty: Nullable<number> = null;
  isHasAutoChangeGoods: boolean = false;
  coefficient: Nullable<number> = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

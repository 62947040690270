import { Box, Button, Typography } from '@material-ui/core';
import { mdiAccountOutline, mdiPhoneOutline, mdiEmailOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import QTextField from 'components/UI/QTextField';
import { RussianPhoneMaskInput } from 'components/UI/RussianPhoneMaskInput';
import { EmailMaskInput } from 'components/UI/TextMaskedInputs';
import { useAllMQ } from 'hooks/useAllMQ';
import { IOrderPersonalValues } from 'modules/order/types/OrderTypes';
import { useCommonStyles } from 'styles/commonStyles';

interface IPersonalDataProps {
  values: IOrderPersonalValues;
  onHandleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PersonalData: React.FC<IPersonalDataProps> = observer(props => {
  const { values, onHandleChange } = props;
  const { orderStore } = useRootStore();

  const { isMD } = useAllMQ();
  const commonClasses = useCommonStyles();

  const handleBack = () => {
    orderStore.setIsPersonalDataScreen(false);
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="h5" className={commonClasses.onSurfacePrimary}>
          Введите данные
        </Typography>
      </Box>
      <Box mb={3}>
        <QTextField
          label="ФИО"
          name="fullname"
          value={values.fullname}
          placeholder="Не указано"
          startIconProps={{ icon: <Icon path={mdiAccountOutline} size={1} /> }}
          onChange={onHandleChange}
          required
        />
      </Box>
      <Box mb={3}>
        <QTextField
          label="Номер телефона"
          name="phone"
          value={values.phone}
          placeholder="+7 (000) 000-00-00"
          startIconProps={{ icon: <Icon path={mdiPhoneOutline} size={1} /> }}
          InputProps={{ inputComponent: RussianPhoneMaskInput }}
          onChange={onHandleChange}
          required
        />
      </Box>
      <Box mb={3}>
        <QTextField
          label="E-mail"
          name="email"
          value={values.email}
          placeholder="test@mail.ru"
          startIconProps={{ icon: <Icon path={mdiEmailOutline} size={1} /> }}
          InputProps={{ inputComponent: EmailMaskInput }}
          onChange={onHandleChange}
        />
      </Box>
      <Box mb={{ xxs: 3, lg: 0 }}>
        <QTextField
          multiline
          name="description"
          value={values.description}
          minRows={3}
          label={
            <>
              Комментарий <span className={commonClasses.onSurfaceTertiary}>(Опционально)</span>
            </>
          }
          placeholder="Введите комментарий"
          onChange={onHandleChange}
        />
      </Box>
      {isMD && (
        <>
          <Box mb={3}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Оформить заявку
            </Button>
          </Box>
          <Button onClick={handleBack} fullWidth variant="contained" color="secondary">
            Назад
          </Button>
        </>
      )}
    </>
  );
});

export default PersonalData;

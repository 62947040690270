import AbstractModel from 'base/AbstractModel';
import { Nullable } from 'base/types/BaseTypes';

import { Good } from './Good';

export class GoodTemplate extends AbstractModel {
  good: Nullable<Good> = null;
  autoChangeGoods: Good[] = [];

  constructor(props: any) {
    super();
    this.load(props);

    this.good = new Good(props?.good);
  }
}

import { Box, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { ICategory } from 'modules/categories/types/CategoriesTypes';
import { useCommonStyles } from 'styles/commonStyles';
import { useCustomTypography } from 'styles/customTypography';

import { useCardStyles } from './styles';

interface ICardProps extends ICategory {
  path: string;
}

const ReadySolution: React.FC<ICardProps> = props => {
  const { previewWebPath, iconWebPath, title, path } = props;

  const theme = useTheme();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  return (
    <div className={cardClasses.card}>
      <Link className={clsx(classes.link, 'router-link')} to={path}>
        <div className={cardClasses.topLine}>
          <div
            className={cardClasses.img}
            style={{
              background: `linear-gradient(0deg, ${theme.palette.primary.main}, ${theme.palette.primary.main}), url(${previewWebPath}) no-repeat center center/cover`,
            }}
          />
          {iconWebPath && (
            <div className={cardClasses.iconWrap}>
              <div
                className={cardClasses.icon}
                style={{
                  background: `linear-gradient(0deg, ${theme.palette.primary.main}, ${theme.palette.primary.main}), url(${iconWebPath}) no-repeat center center/cover`,
                }}
              />
            </div>
          )}
        </div>
      </Link>
      <Box p={2}>
        <Typography
          className={clsx(typography.headline6Strong, commonClasses.onSurfacePrimary, commonClasses.cursorDefault)}
        >
          {title}
        </Typography>
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  bottomLink: {
    display: 'block',
  },
  infoIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    color: theme.palette.primary.main,
  },
}));

export default ReadySolution;

import camelize from 'camelize';

import { Nullable } from 'base/types/BaseTypes';

import GoodsApiRepository from './GoodsApiRepository';
import GoodsFactory from './GoodsFactory';
import { ChangeGoodsDto } from './forms/ChangeGoodsDto';
import { Good } from './models/Good';
import { GoodTemplate } from './models/GoodTemplate';
import { IChangeGoodsValues } from './types/GoodsTypes';

export default class GoodsService {
  goodsApi: GoodsApiRepository;
  goodsFactory: GoodsFactory;

  constructor() {
    this.goodsApi = new GoodsApiRepository();
    this.goodsFactory = new GoodsFactory();
  }

  getGoods = async (systemId: number, region: number | null): Promise<GoodTemplate[]> => {
    const { data } = await this.goodsApi.getGoods(systemId, region);

    const goods: GoodTemplate[] = data.data.map((good: GoodTemplate) => {
      const autoChangeGoods = this.goodsFactory.createList<Good>(Good, camelize(good.autoChangeGoods));
      return { ...good, autoChangeGoods };
    });

    return this.goodsFactory.createList<GoodTemplate>(GoodTemplate, camelize(goods));
  };

  getGoodById = async (goodId: number, region: number | null): Promise<Good[]> => {
    const { data } = await this.goodsApi.getGoodById(goodId, region);
    return this.goodsFactory.createList<Good>(Good, camelize(data.data));
  };

  changeGoods = async (values: IChangeGoodsValues): Promise<GoodTemplate[]> => {
    const changeGoodsDto = ChangeGoodsDto.populate({ ...values }) as ChangeGoodsDto;

    const { data } = await this.goodsApi.changeGoods(changeGoodsDto);
    return this.goodsFactory.createList<GoodTemplate>(GoodTemplate, camelize(data.data));
  };

  findGoodIndexById = (list: GoodTemplate[], id: Nullable<number>): number =>
    list.findIndex(item => item.good?.id === id);

  findGoodWithAutochangedGoodsIndex = (list: GoodTemplate[], id: Nullable<number>): number =>
    list.findIndex(item => item.autoChangeGoods.some(good => good.id === id));

  updateGoodWithAutochangedGoods = (
    parentGood: GoodTemplate,
    currentGoodId: Nullable<number>,
    selectedGood: GoodTemplate,
  ): GoodTemplate => {
    const updatedAutoChangeGoods = parentGood.autoChangeGoods.map(good =>
      good.id === currentGoodId ? selectedGood.good! : good,
    );

    return { ...parentGood, autoChangeGoods: updatedAutoChangeGoods } as GoodTemplate;
  };

  getSlicedGoods = (goodsList: GoodTemplate[], currentGood: Good, selectedGood: GoodTemplate): GoodTemplate[] => {
    const currentItemIndex = this.findGoodIndexById(goodsList, currentGood.id);

    if (currentItemIndex !== -1) {
      return [...goodsList.slice(0, currentItemIndex), selectedGood, ...goodsList.slice(currentItemIndex + 1)];
    }

    const parentGoodIndex = this.findGoodWithAutochangedGoodsIndex(goodsList, currentGood.id);

    if (parentGoodIndex !== -1) {
      const parentGood = goodsList[parentGoodIndex];
      const updatedGoodWithAutochangedGoods = this.updateGoodWithAutochangedGoods(
        parentGood,
        currentGood.id,
        selectedGood,
      );

      return [
        ...goodsList.slice(0, parentGoodIndex),
        updatedGoodWithAutochangedGoods,
        ...goodsList.slice(parentGoodIndex + 1),
      ];
    }

    return goodsList;
  };

  getFlatGoodsList = (goodsList: GoodTemplate[]): Good[] => {
    let flatGoodsList: Good[] = [];

    goodsList.forEach((item: GoodTemplate) => {
      if (item.good) {
        flatGoodsList = [...flatGoodsList, item.good, ...item.autoChangeGoods];
      }
    });

    return flatGoodsList;
  };
}

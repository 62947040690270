import { Box, Container, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useRootStore } from 'base/hooks/useRootStore';
import { IStringParams } from 'base/types/BaseTypes';
import Loader from 'components/UI/Loader';
import { useAllMQ } from 'hooks/useAllMQ';
import { useCommonStyles } from 'styles/commonStyles';

interface ISubCategoriesDetailScreenProps {}

const SubCategoriesDetailScreen: React.FC<ISubCategoriesDetailScreenProps> = observer(() => {
  const { categoriesStore } = useRootStore();
  const params = useParams<IStringParams>();

  const { isXS } = useAllMQ();
  const commonClasses = useCommonStyles();

  // Effects
  useEffect(() => {
    if (params?.subCategoryId && !categoriesStore.subCategory) {
      categoriesStore.getCategoryById(Number(params?.subCategoryId));
    }

    return () => {
      categoriesStore.resetSubCategory();
    };
  }, [categoriesStore, params?.subCategoryId]);

  if (categoriesStore.loading) {
    return <Loader minHeight="100vh" />;
  }

  return (
    <Box pt={3}>
      <Container maxWidth="lg">
        <div>
          <Typography className={commonClasses.onSurfacePrimary} variant={isXS ? 'body2' : 'body1'}>
            {categoriesStore.subCategory?.cat?.description}
          </Typography>
        </div>
      </Container>
    </Box>
  );
});

export default SubCategoriesDetailScreen;

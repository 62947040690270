import { Box, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';

import { makePath } from 'base/routes/utils/makePath';
import { IStringParams } from 'base/types/BaseTypes';
import InfoTag from 'components/InfoTag';
import { getWithSpace } from 'helpers/getWithSpace';
import { useAllMQ } from 'hooks/useAllMQ';
import { System } from 'modules/systems/models/System';
import { routes } from 'screens/routes';
import { useCommonStyles } from 'styles/commonStyles';
import { useCustomTypography } from 'styles/customTypography';

import { ReactComponent as airNoise } from 'assets/icons/air-noise.svg';
import discount from 'assets/icons/discount.png';
import { ReactComponent as impactNoise } from 'assets/icons/impact-noise.svg';
import { ReactComponent as soundproofing } from 'assets/icons/soundproofing.svg';
import { ReactComponent as systemThickness } from 'assets/icons/system-thickness.svg';

interface ISystemsItemProps {
  data: System;
}

const SystemsItem: React.FC<ISystemsItemProps> = observer(props => {
  const {
    id,
    title,
    price,
    imagesWebPath,
    indicatorThickness,
    indicatorRW,
    indicatorDeltaRw,
    indicatorDeltaLNW,
    isAction,
  } = props.data;

  const { isXS } = useAllMQ();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();
  const params = useParams<IStringParams>();

  // Renders
  return (
    <Link
      to={makePath(routes.SystemDetailScreen.path, [
        { p: 'categoryId', v: params.categoryId },
        { p: 'subCategoryId', v: params.subCategoryId },
        { p: 'systemId', v: id as number },
      ])}
      className={clsx('router-link', classes.item)}
    >
      {imagesWebPath?.length && (
        <div className={commonClasses.imgWrap}>
          {isAction && <img className={commonClasses.imgAction} width={24} height={24} src={discount} alt="" />}
          <img className={commonClasses.img} src={imagesWebPath[0]} alt="" />
        </div>
      )}
      <Box>
        <Box mb={{ xxs: 1.5, md: 3 }}>
          <Typography
            variant={isXS ? 'body1' : 'inherit'}
            className={clsx({ [typography.headline6Strong]: !isXS }, commonClasses.onSurfacePrimary)}
          >
            {title}
          </Typography>
        </Box>
        <Box mb={{ md: 3 }}>
          <ul className={classes.tagList}>
            {!!indicatorThickness && indicatorThickness.toString() !== '0' && (
              <>
                <Tooltip placement="bottom" title="Толщина звукоизоляционной обшивки">
                  <li>
                    <InfoTag tagTitle={`${indicatorThickness} мм`} icon={systemThickness} />
                  </li>
                </Tooltip>
              </>
            )}
            {!!indicatorRW && indicatorRW.toString() !== '0' && (
              <>
                <Tooltip placement="bottom" title="Индекс изоляции воздушного шума общей конструкции Rw">
                  <li>
                    <InfoTag tagTitle={`${indicatorRW} дБ`} icon={soundproofing} />
                  </li>
                </Tooltip>
              </>
            )}
            {!!indicatorDeltaRw && indicatorDeltaRw.toString() !== '0' && (
              <>
                <Tooltip placement="bottom" title="Индекс улучшения изоляции воздушного шума ΔRw">
                  <li>
                    <InfoTag tagTitle={`${indicatorDeltaRw} дБ`} icon={airNoise} />
                  </li>
                </Tooltip>
              </>
            )}
            {!!indicatorDeltaLNW && indicatorDeltaLNW.toString() !== '0' && (
              <>
                <Tooltip placement="bottom" title="Индекс улучшения изоляции ударного шума ΔLnw">
                  <li>
                    <InfoTag tagTitle={`${indicatorDeltaLNW} дБ`} icon={impactNoise} />
                  </li>
                </Tooltip>
              </>
            )}
          </ul>
        </Box>
        <Typography
          variant={isXS ? 'subtitle1' : 'inherit'}
          className={clsx({ [typography.headline6Strong]: !isXS }, commonClasses.primary)}
        >
          {getWithSpace(price)} ₽ за м²
        </Typography>
      </Box>
    </Link>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  tagList: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > li': {
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
  },
  item: {
    display: 'flex',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
    },
  },
}));

export default SystemsItem;

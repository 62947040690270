import { Menu, MenuProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface IMenuProps extends MenuProps {}

const QMenu: React.FC<IMenuProps> = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Menu classes={{ list: classes.list }} {...rest}>
      <div>{children}</div>
    </Menu>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    maxHeight: 590,
    width: '100%',
  },
}));

export default QMenu;

import AbstractModel from 'base/AbstractModel';
import { Nullable } from 'base/types/BaseTypes';

export class TypesModel extends AbstractModel {
  id: Nullable<number> = null;
  title: Nullable<string> = null;
  info: Nullable<string> = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

import { Breadcrumb } from 'modules/breadcrumbs/models/Breadcrumb';

import CategoriesApiRepository from './CategoriesApiRepository';
import CategoriesFactory from './CategoriesFactory';
import { Category } from './models/Category';
import { SubCategory } from './models/SubCategory';

export default class CategoriesService {
  categoriesApi: CategoriesApiRepository;
  categoriesFactory: CategoriesFactory;

  constructor() {
    this.categoriesApi = new CategoriesApiRepository();
    this.categoriesFactory = new CategoriesFactory();
  }

  getCategories = async (): Promise<{ list: Category[]; breadcrumbs: Breadcrumb[] }> => {
    const { data } = await this.categoriesApi.getCategories();
    const list = this.categoriesFactory.createList<Category>(Category, data.data);
    const breadcrumbs = this.categoriesFactory.createList<Breadcrumb>(Breadcrumb, data.breadcrumbs);

    return { list, breadcrumbs };
  };

  getCategoryById = async (categoryId: number): Promise<{ subCategory: SubCategory; breadcrumbs: Breadcrumb[] }> => {
    const { data } = await this.categoriesApi.getCategoryById(categoryId);
    const subCategory = this.categoriesFactory.create<SubCategory>(SubCategory, data.data);
    const breadcrumbs = this.categoriesFactory.createList<Breadcrumb>(Breadcrumb, data.breadcrumbs);
    return { subCategory, breadcrumbs };
  };
}

import { makeStyles, Theme } from '@material-ui/core';

export const useCustomTypography = makeStyles((theme: Theme) => ({
  headline6Strong: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
}));

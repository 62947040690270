import { Breadcrumbs, makeStyles, Theme, Typography } from '@material-ui/core';
import { mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Breadcrumb } from 'modules/breadcrumbs/models/Breadcrumb';
import { useCommonStyles } from 'styles/commonStyles';

interface IQBreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

const QBreadcrumbs: React.FC<IQBreadcrumbsProps> = ({ breadcrumbs }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <Breadcrumbs
      classes={{ root: classes.root, ol: classes.ol, li: classes.li }}
      separator={<Icon path={mdiChevronRight} size={1} />}
    >
      {breadcrumbs.map((item, index) =>
        breadcrumbs.length - 1 !== index ? (
          <Link key={index} className={clsx('router-link', classes.link)} to={item?.path ?? ''}>
            <Typography variant="caption">{item.title}</Typography>
          </Link>
        ) : (
          <Typography key={index} variant="caption" className={commonClasses.onSurfaceSecondary}>
            {item.title}
          </Typography>
        ),
      )}
    </Breadcrumbs>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    width: '100%',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '50%',
      width: 40,
      height: '100%',
      pointerEvents: 'none',
      transform: 'translateY(-50%)',
      background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
    },
  },
  ol: {
    flexWrap: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden',
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    maxWidth: '100%',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0',
      height: '0',
    },
    '&': {
      msOverflowStyle: 'none',
      overflow: '-moz-scrollbars-none',
    },
  },
  li: {
    whiteSpace: 'nowrap',
  },
  link: {
    color: theme.palette.primary.main,
  },
  iconWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
  },
}));

export default QBreadcrumbs;

import { makeStyles, Theme } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';

export const useCardStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    transition: transitions.create('transform'),
    '&:hover': {
      transform: 'translateY(-2px)',
    },
  },
  card: {
    overflow: 'hidden',
    borderRadius: 8,
    border: `1px solid ${theme.palette.outline.main}`,
    height: '100%',
  },
  topLine: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 136,
    width: '100%',
    backgroundBlendMode: 'hue, normal',
    [theme.breakpoints.down('xs')]: {
      height: 88,
    },
  },
  iconWrap: {
    position: 'absolute',
    top: '50%',
    left: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 56,
    width: 56,
    padding: theme.spacing(1),
    borderRadius: 8,
    overflow: 'hidden',
    background: theme.palette.surface.main,
    transform: 'translateY(-50%)',
  },
  icon: {
    width: '100%',
    height: '100%',
    backgroundBlendMode: 'hue, normal',
  },
}));

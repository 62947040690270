import { Box, Button, DialogProps, Typography } from '@material-ui/core';
import clsx from 'clsx';

import QModal from 'components/UI/QModal';
import { useCommonStyles } from 'styles/commonStyles';
import { useCustomTypography } from 'styles/customTypography';

interface ISuccessModalProps extends DialogProps {
  onHandleClose: () => void;
}

const SuccessModal: React.FC<ISuccessModalProps> = props => {
  const { onHandleClose, ...rest } = props;
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  return (
    <QModal onClose={onHandleClose} {...rest}>
      <Box mb={2}>
        <Typography className={clsx(commonClasses.onSurfacePrimary, typography.headline6Strong)} align="center">
          Заявка оформлена.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography className={commonClasses.onSurfacePrimary} align="center">
          Спасибо!
          <br /> Мы свяжемся с вами в ближайшее время.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Button onClick={onHandleClose} variant="contained" color="primary">
          Закрыть
        </Button>
      </Box>
    </QModal>
  );
};

export default SuccessModal;

import { Nullable } from 'base/types/BaseTypes';

import OrderApiRepository from './OrderApiRepository';
import OrderFactory from './OrderFactory';
import { OrderCalcDto } from './forms/OrderCalcDto';
import { OrderCreateDto } from './forms/OrderCreateDto';
import { OrderCalcModel } from './models/OrderCalcModel';
import { OrderModel } from './models/OrderModel';
import { IGood, IOrderCalcValues, IOrderCreateValues } from './types/OrderTypes';

export default class OrderService {
  orderApi: OrderApiRepository;
  orderFactory: OrderFactory;

  constructor() {
    this.orderApi = new OrderApiRepository();
    this.orderFactory = new OrderFactory();
  }

  orderCalc = async (values: IOrderCalcValues): Promise<OrderCalcModel> => {
    const orderCalcDto = OrderCalcDto.populate({ ...values }) as OrderCalcDto;

    const { data } = await this.orderApi.orderCalc(orderCalcDto);
    return this.orderFactory.create<OrderCalcModel>(OrderCalcModel, data.data);
  };

  orderCreate = async (values: IOrderCreateValues): Promise<OrderModel[]> => {
    const preDto = {
      agentId: values.agentId,
      customer_fio: values.fullname,
      customer_phone: values.phone,
      customer_email: values.email,
      customer_description: values.description,
    };
    const orderCalcDto = OrderCreateDto.populate({ ...values, ...preDto }) as OrderCreateDto;

    const { data } = await this.orderApi.orderCreate(orderCalcDto);
    return this.orderFactory.createList<OrderModel>(OrderModel, data.data);
  };

  getOrderCalcInfoObject = (orderCalcInfo: Nullable<OrderCalcModel>) => {
    if (orderCalcInfo) {
      return orderCalcInfo.order[0].goods.reduce<{ [key: number]: IGood }>((acc, goodInfo) => {
        acc[goodInfo.goods.id as number] = goodInfo;
        return acc;
      }, {});
    }

    return {};
  };

  getAmount = (coefficient: number, areaForCalc: number) => {
    const fixedAmount = areaForCalc * coefficient;

    return Math.ceil(Number(fixedAmount.toFixed(2)));
  };

  getTotalPrice = (pricePerOne: number, amount: number) => {
    const total = amount * pricePerOne;

    return Math.ceil(Number(total));
  };
}

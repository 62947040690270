import { Box, Container, IconButton } from '@material-ui/core';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import ReactNotification from 'react-notifications-component';

import Location from 'components/Location';
import { useCommonStyles } from 'styles/commonStyles';

interface IChooseLocationProps {}

const ChooseLocation: React.FC<IChooseLocationProps> = () => {
  const commonClasses = useCommonStyles();

  // Handlers
  const handleCloseWidget = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (window.parent) {
      window.parent.postMessage({ type: 'closeTechnosonusWidget' }, '*');
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Location />
        </Box>
        <Box position="absolute" top={10} right={3}>
          <IconButton onClick={handleCloseWidget} className={commonClasses.onSurfaceSecondary}>
            <Icon path={mdiClose} size={1} />
          </IconButton>
        </Box>
      </Container>
      <ReactNotification />
    </>
  );
};

export default ChooseLocation;

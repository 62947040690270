import { makeAutoObservable, runInAction } from 'mobx';

import { rootStore } from 'base/RootStore';
import { Nullable } from 'base/types/BaseTypes';

import CategoriesService from './CategoriesService';
import { Category } from './models/Category';
import { SubCategory } from './models/SubCategory';

export class CategoriesStore {
  loading: boolean = false;

  categoriesList: Category[] = [];
  subCategory: Nullable<SubCategory> = null;

  private categoriesService: CategoriesService;

  constructor() {
    makeAutoObservable(this);
    this.categoriesService = new CategoriesService();
  }

  getCategories = () => {
    this.setLoading(true);

    this.categoriesService
      .getCategories()
      .then(({ list, breadcrumbs }) => {
        runInAction(() => {
          this.categoriesList = list;
          rootStore.breadcrumbsStore.setBreadcrumbs(breadcrumbs);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  getCategoryById = (categoryId: number) => {
    this.setLoading(true);

    this.categoriesService
      .getCategoryById(categoryId)
      .then(({ subCategory, breadcrumbs }) => {
        runInAction(() => {
          this.subCategory = subCategory;
          rootStore.breadcrumbsStore.setBreadcrumbs(breadcrumbs);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  resetSubCategory = () => {
    this.subCategory = null;
  };
}

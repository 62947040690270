import { Box, Button, DialogProps, Typography } from '@material-ui/core';

import QModal from 'components/UI/QModal';
import { useCommonStyles } from 'styles/commonStyles';

interface IDataConfirmModalProps extends DialogProps {
  onHandleChangeRoute: () => void;
  onHandleCloseConfirmModal: () => void;
}

const DataConfirmModal: React.FC<IDataConfirmModalProps> = props => {
  const { onHandleChangeRoute, onHandleCloseConfirmModal, ...rest } = props;
  const commonClasses = useCommonStyles();

  return (
    <QModal onClose={onHandleCloseConfirmModal} {...rest}>
      <Box mb={1}>
        <Typography variant="body1" align="center" className={commonClasses.onSurfacePrimary}>
          Вы точно хотите вернуться?
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="body1" align="center" className={commonClasses.onSurfaceSecondary}>
          При этом введенные вами данные не сохранятся.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box mr={2}>
          <Button onClick={onHandleChangeRoute} variant="contained" color="primary">
            Вернуться
          </Button>
        </Box>
        <Button onClick={onHandleCloseConfirmModal} variant="contained" color="secondary">
          Отменить
        </Button>
      </Box>
    </QModal>
  );
};

export default DataConfirmModal;

/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles, Theme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { Nullable } from 'base/types/BaseTypes';
import { useAllMQ } from 'hooks/useAllMQ';
import { Good } from 'modules/goods/models/Good';
import { IGood } from 'modules/order/types/OrderTypes';

import ChooseBtn from './ChooseBtn';
import SCItemContent from './SCItemContent';
import SCItemContentMobile from './SCItemContentMobile';

interface ISCSubItemProps {
  good: Good;
  selectedId: Nullable<number>;
  onHandleSelect: () => void;
  calcGood?: IGood | null;
}

const SCSubItem: React.FC<ISCSubItemProps> = observer(props => {
  const { good, selectedId, calcGood, onHandleSelect } = props;

  const { orderStore } = useRootStore();

  const classes = useStyles();
  const { isSM } = useAllMQ();

  const amount = useMemo(() => {
    if (!calcGood) {
      return good.coefficient ? orderStore.getAmount(good.coefficient) : 1;
    }

    return calcGood.qtyTotal;
  }, [calcGood, good.coefficient]);

  const totalPrice = useMemo(() => {
    if (!calcGood) {
      return good.pricePerOne ? orderStore.getTotalPrice(good.pricePerOne, Number(amount)) : 1;
    }

    return calcGood.priceTotal;
  }, [calcGood, good.pricePerOne, amount]);

  // Renders
  if (good) {
    return (
      <div key={good.id} className={classes.item}>
        {isSM ? (
          <SCItemContentMobile good={good} amount={amount} totalPrice={totalPrice}>
            <ChooseBtn onClick={onHandleSelect} isSelected={good.id === selectedId} />
          </SCItemContentMobile>
        ) : (
          <SCItemContent good={good} amount={amount} totalPrice={totalPrice}>
            <ChooseBtn onClick={onHandleSelect} isSelected={good.id === selectedId} />
          </SCItemContent>
        )}
      </div>
    );
  }

  return <></>;
});

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1.5),
    },
  },
}));

export default SCSubItem;

import AbstractModel from 'base/AbstractModel';
import { IBreadcrumbs, Nullable } from 'base/types/BaseTypes';

import { Category } from './Category';

export class SubCategory extends AbstractModel {
  cat: Nullable<Category> = null;
  subcats: Category[] = [];
  breadcrumbs: IBreadcrumbs[] = [];

  constructor(props: any) {
    super();
    this.load(props);
  }
}

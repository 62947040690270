import {
  Box,
  Theme,
  FormLabel,
  TextField,
  makeStyles,
  IconButton,
  FormLabelProps,
  IconButtonProps,
  StandardTextFieldProps,
} from '@material-ui/core';
import { mdiAlertCircle } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import { ReactNode } from 'react';

import QIcon from './QIcon';

interface IIconBtnProps extends IconButtonProps {
  src?: string;
  icon?: ReactNode;
}

interface IQTextFieldProps extends StandardTextFieldProps {
  label?: string | React.ReactElement;
  labelProps?: FormLabelProps;
  startIconProps?: IIconBtnProps;
  endIconProps?: IIconBtnProps;
}

const QTextField: React.FC<IQTextFieldProps> = props => {
  const { label, labelProps, startIconProps, endIconProps, className, disabled, error, InputProps, ...rest } = props;
  const classes = useStyles();

  const renderStartIcon = () => {
    if (startIconProps) {
      const { src, icon: Icon, disabled = true, ...startIconPropsRest } = startIconProps;

      return (
        <IconButton disabled={disabled} className={clsx(classes.iconBtn, classes.iconBtnStart)} {...startIconPropsRest}>
          {src?.length ? <QIcon src={src} width={24} height={24} /> : Icon}
        </IconButton>
      );
    }

    return <></>;
  };

  const renderEndIcon = () => {
    if (endIconProps) {
      const { src, icon: Icon, disabled = true, ...endIconPropsRest } = endIconProps;

      return (
        <IconButton disabled={disabled} className={clsx(classes.iconBtn, classes.iconBtnEnd)} {...endIconPropsRest}>
          {src?.length ? <QIcon src={src} width={24} height={24} /> : Icon}
        </IconButton>
      );
    }

    return <></>;
  };

  const renderErrorIcon = () => {
    return (
      <IconButton disabled className={clsx(classes.errorColor, classes.iconBtn, classes.iconBtnEnd)}>
        <Icon path={mdiAlertCircle} size={1} />
      </IconButton>
    );
  };

  return (
    <Box textAlign="left">
      {label && (
        <FormLabel className={clsx({ [classes.errorColor]: error })} {...labelProps}>
          {label}
        </FormLabel>
      )}
      <TextField
        fullWidth
        disabled={disabled}
        color="primary"
        variant="outlined"
        InputProps={{
          startAdornment: renderStartIcon(),
          endAdornment: error ? renderErrorIcon() : renderEndIcon(),
          ...InputProps,
        }}
        classes={{
          root: clsx(classes.textField, { startIcon: !!startIconProps, endIcon: !!endIconProps }),
        }}
        error={error}
        {...rest}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  iconBtn: {
    position: 'absolute',
    top: '50%',
    width: 'auto',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.onSurface.tertiary,
    padding: 0,
    background: 'transparent',
    transform: 'translateY(-50%)',
    zIndex: 1,
  },
  iconBtnStart: {
    left: theme.spacing(1.5),
  },
  iconBtnEnd: {
    right: theme.spacing(1.5),
  },
  errorColor: {
    color: `${theme.palette.error.main} !important`,
  },
  textField: {
    '&.startIcon': {
      '& input': {
        paddingLeft: theme.spacing(6),
      },
    },
    '&.endIcon': {
      '& input': {
        paddingRight: theme.spacing(6.5),
      },
    },
  },
}));

export default QTextField;

import AbstractModel from 'base/AbstractModel';
import { Nullable } from 'base/types/BaseTypes';

export class Settings extends AbstractModel {
  color: Nullable<string> = null;
  regionId: Nullable<number> = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

import { Box, Button, DialogProps, Typography } from '@material-ui/core';

import QModal from 'components/UI/QModal';
import { useCommonStyles } from 'styles/commonStyles';

interface IDeleteConfirmModalProps extends DialogProps {
  onHandleClose: () => void;
  onHandleDelete: () => void;
}

const DeleteConfirmModal: React.FC<IDeleteConfirmModalProps> = props => {
  const { onHandleClose, onHandleDelete, ...rest } = props;
  const commonClasses = useCommonStyles();

  return (
    <QModal onClose={onHandleClose} {...rest}>
      <Box mb={3}>
        <Typography className={commonClasses.onSurfacePrimary} variant="body1" align="center">
          Вы точно хотите удалить данный товар из заказа?
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box mr={2}>
          <Button onClick={onHandleDelete} variant="contained" color="primary">
            Удалить
          </Button>
        </Box>
        <Button onClick={onHandleClose} variant="contained" color="secondary">
          Отменить
        </Button>
      </Box>
    </QModal>
  );
};

export default DeleteConfirmModal;

import { makeAutoObservable, runInAction } from 'mobx';

import SettingsService from './SettingsService';

export class SettingsStore {
  settingsLoader: boolean = false;
  color: string | null = null;
  regionId: number | null = null;
  closeConfirmModal: boolean = false;

  private settingsService: SettingsService;

  constructor() {
    makeAutoObservable(this);
    this.settingsService = new SettingsService();
  }

  getSettings = (agentId: string | number) => {
    this.setLoading(true);

    this.settingsService
      .getSettings(agentId)
      .then(({ color, regionId }) => {
        runInAction(() => {
          this.color = color;
          this.regionId = regionId;
        });
      })
      .catch(e => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  setLoading = (value: boolean) => {
    this.settingsLoader = value;
  };

  setCloseConfirmModal = (value: boolean) => {
    this.closeConfirmModal = value;
  };
}

import { Box, Button, Divider, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { mdiChevronUp } from '@mdi/js';
import { Icon } from '@mdi/react';

interface IHideButtonProps {
  show: boolean;
  onHandleHideSubItems: () => void;
}

const HideButton: React.FC<IHideButtonProps> = ({ show, onHandleHideSubItems }) => {
  const classes = useStyles();

  if (!show) {
    return <></>;
  }

  return (
    <div className={classes.hideButtonWrap}>
      <Box position="relative" zIndex="1">
        <Button
          size="small"
          variant="contained"
          color="secondary"
          endIcon={<Icon path={mdiChevronUp} size={1} />}
          className={classes.moreBtn}
          onClick={onHandleHideSubItems}
        >
          Свернуть
        </Button>
      </Box>
      <Divider className={classes.hideButtonDivider} />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  moreBtn: {
    background: '#F2F2F2',
    '&:hover': {
      background: '#F2F2F2',
    },
    '& .MuiButton-endIcon': {
      color: theme.palette.onSurface.tertiary,
    },
  },
  hideButtonWrap: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  hideButtonDivider: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    border: `1px solid ${theme.palette.outline.main}`,
    transform: 'translate(-50%, -50%)',
    zIndex: -1,
  },
}));

export default HideButton;

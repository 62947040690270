import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';

export const BaseNumberInput = (props: any, otherProps?: NumberFormatProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        if (onChange) {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }
      }}
      {...otherProps}
    />
  );
};

export const NumberInput = (props: any) => {
  return BaseNumberInput(props, {
    decimalScale: 0,
  });
};

export const AreaNumberInput = (props: any) => {
  return BaseNumberInput(props, {
    decimalScale: 0,
    isAllowed: (values: NumberFormatValues) => {
      return values.value === '' || Number(values.floatValue ?? 0) >= 1;
    },
  });
};

export const CodeFormatInput = (props: any) => {
  return BaseNumberInput(props, {
    format: '####',
    mask: '_',
  });
};

export const MoneyInput = (props: any) => {
  return BaseNumberInput(props, {
    thousandSeparator: true,
  });
};

export const PhoneFormat = (props: any) => {
  return BaseNumberInput(props, {
    format: '+# (###) ###-##-##',
    displayType: 'text',
  });
};

import { Theme, withStyles, InputBase, Select, SelectProps, makeStyles } from '@material-ui/core';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';

interface IQSelectProps extends SelectProps {}

const QSelect: React.FC<IQSelectProps> = props => {
  const { children, ...rest } = props;
  const classes = useStyles();
  return (
    <Select
      {...rest}
      fullWidth
      MenuProps={{ classes: { paper: classes.paper } }}
      IconComponent={() => <Icon path={mdiChevronDown} size={1} />}
      input={<SelectInput />}
    >
      {children}
    </Select>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {},
}));

const SelectInput = withStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    ...theme.typography.subtitle2,
    color: theme.palette.onSurface.primary,
    background: theme.palette.overlay.main,
    border: 'none',
    borderRadius: 4,
    paddingRight: theme.spacing(2),
    fontWeight: 'normal',
    '& > svg': {
      width: '20px !important',
      height: '20px !important',
      minWidth: '20px !important',
      minHeight: '20px !important',
      color: theme.palette.onSurface.tertiary,
    },
  },
  input: {
    padding: `${theme.spacing(0.5, 1.5, 0.5, 2)} !important`,
  },
}))(InputBase);

export default QSelect;

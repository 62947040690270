import AbstractDto from 'base/AbstractDto';
import { Nullable } from 'base/types/BaseTypes';

export class ChangeGoodsDto extends AbstractDto {
  good: Nullable<number> = null;
  region: Nullable<number> = null;
  system: Nullable<number> = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

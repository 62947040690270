import { Grid, Box, Typography, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { Link, useParams } from 'react-router-dom';

import { makePath } from 'base/routes/utils/makePath';
import { IStringParams, Nullable } from 'base/types/BaseTypes';
import TextButton from 'components/UI/TextButton';
import { getWithSpace } from 'helpers/getWithSpace';
import { Good } from 'modules/goods/models/Good';
import { routes } from 'screens/routes';
import { useCommonStyles } from 'styles/commonStyles';

import discount from 'assets/icons/discount.png';

interface ISCItemContentMobileProps {
  good: Good;
  amount: Nullable<number>;
  totalPrice: Nullable<number>;
}

const SCItemContentMobile: React.FC<PropsWithChildren<ISCItemContentMobileProps>> = props => {
  const { good, amount, totalPrice, children } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const params = useParams<IStringParams>();

  // Renders
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs className={classes.col}>
        <Box display="flex" alignItems="center" mb={{ xxs: 1, md: 0 }}>
          {!!good.imagesWebPath?.length && (
            <div className={commonClasses.imgWrap}>
              {good.isAction && (
                <img className={commonClasses.imgAction} width={24} height={24} src={discount} alt="" />
              )}
              <img className={commonClasses.img} src={good.imagesWebPath[0]} alt="" />
            </div>
          )}
          <div>
            <Box mb={1}>
              <Link
                to={makePath(routes.SystemCompositionDetailScreen.path, [
                  { p: 'categoryId', v: params.categoryId },
                  { p: 'subCategoryId', v: params.subCategoryId },
                  { p: 'systemId', v: params.systemId },
                  { p: 'systemCompositionId', v: good.id ?? '' },
                ])}
                className={clsx('router-link', classes.link)}
              >
                <TextButton color="primary" className={classes.itemTitle}>
                  <Typography variant="body2">{good.title}</Typography>
                </TextButton>
              </Link>
            </Box>
          </div>
        </Box>
        <Box mb={1}>
          <Typography component="div" variant="body1" className={commonClasses.onSurfacePrimary}>
            <Box display="flex" alignItems="center">
              <Box mr={1.5}>{getWithSpace(good.pricePerOne)} ₽</Box>
              <Box mr={1.5}>×</Box>
              <Box mr={1.5}>{amount} шт.</Box>
              <Box mr={1.5}>=</Box>
              <Typography variant="subtitle1">{getWithSpace(totalPrice)} ₽</Typography>
            </Box>
          </Typography>
        </Box>
        {children}
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  itemTitle: {
    textAlign: 'inherit',
    padding: '0',
  },
  link: {
    display: 'inline-block',
  },
  col: {
    flexBasis: '100%',
    maxWidth: '100%',
  },
}));

export default SCItemContentMobile;

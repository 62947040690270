import { makeAutoObservable, runInAction } from 'mobx';

import { Nullable } from 'base/types/BaseTypes';

import LocationService from './LocationService';
import { LocationModel } from './models/LocationModel';

export class LocationStore {
  loading: boolean = false;
  locationList: LocationModel[] = [];
  currentLocation: Nullable<LocationModel> = null;

  confirmModal: boolean = false;

  private locationService: LocationService;

  constructor() {
    makeAutoObservable(this);
    this.locationService = new LocationService();
  }

  getLocationList = () => {
    this.setLoading(true);

    this.locationService
      .getLocationList()
      .then(locationList => {
        runInAction(() => {
          this.locationList = locationList;
        });
      })
      .catch(e => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  setCurrentLocation = (currentLocation: LocationModel) => {
    this.currentLocation = currentLocation;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setConfirmModal = (confirmModal: boolean) => {
    this.confirmModal = confirmModal;
  };
}

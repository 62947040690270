import { Nullable } from 'base/types/BaseTypes';
import { Breadcrumb } from 'modules/breadcrumbs/models/Breadcrumb';

import SystemsApiRepository from './SystemsApiRepository';
import SystemsFactory from './SystemsFactory';
import { EntityDto } from './forms/EntityDto';
import { SystemDto } from './forms/SystemDto';
import { EntityModel } from './models/Entity';
import { System } from './models/System';
import { IEntityInfoValues, ISystemsValues } from './types/SystemsTypes';

export default class SystemsService {
  systemsApi: SystemsApiRepository;
  systemsFactory: SystemsFactory;

  constructor() {
    this.systemsApi = new SystemsApiRepository();
    this.systemsFactory = new SystemsFactory();
  }

  getSystems = async (values: ISystemsValues, area: string): Promise<{ breadcrumbs: Breadcrumb[]; list: System[] }> => {
    const preDto = { area: Number(area), ...this.getSortValue(values.sort) };
    const systemDto = SystemDto.populate({ ...values, ...preDto }) as SystemDto;

    const { data } = await this.systemsApi.getSystems(systemDto);
    const list = this.systemsFactory.createList<System>(System, data.data);
    const breadcrumbs = this.systemsFactory.createList<Breadcrumb>(Breadcrumb, data.breadcrumbs);

    return { list, breadcrumbs };
  };

  getSystemById = async (systemId: number): Promise<{ system: System[]; breadcrumbs: Breadcrumb[] }> => {
    const { data } = await this.systemsApi.getSystemById(systemId);
    const system = this.systemsFactory.createList<System>(System, data.data);
    const breadcrumbs = this.systemsFactory.createList<Breadcrumb>(Breadcrumb, data.breadcrumbs);

    return { system, breadcrumbs };
  };

  getEntityInfo = async (values: IEntityInfoValues): Promise<EntityModel> => {
    const entityDto = EntityDto.populate({ ...values }) as EntityDto;

    const { data } = await this.systemsApi.getEntityInfo(entityDto);
    return this.systemsFactory.create<EntityModel>(EntityModel, data.data);
  };

  getSystemsFixedPriceList = (systemsList: System[], areaForCalc: number) => {
    return systemsList.map(item => {
      const price = (item.price || 0) / areaForCalc;

      return this.systemsFactory.create<System>(System, { ...item, price: Math.round(price) });
    });
  };

  private getSortValue = (sort: Nullable<string>) => {
    if (sort && sort !== 'default') {
      const [key, value] = sort.split('=');

      return { [key]: value };
    }

    return {};
  };
}

import { Box, Button, Container, makeStyles, Theme, Typography } from '@material-ui/core';
import { mdiDownloadOutline, mdiPlayCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import { saveAs } from 'file-saver';
import { UnregisterCallback } from 'history';
import { observer } from 'mobx-react-lite';
import { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router';

import { useRootStore } from 'base/hooks/useRootStore';
import { IStringParams } from 'base/types/BaseTypes';
import ImagesGallery from 'components/UI/ImagesGallery';
import Loader from 'components/UI/Loader';
import { useAllMQ } from 'hooks/useAllMQ';
import { useCommonStyles } from 'styles/commonStyles';
import { useCustomTypography } from 'styles/customTypography';

import AddCartButtons from './components/SystemDetailScreen/AddCartButtons';
import CalcForm from './components/SystemDetailScreen/CalcForm';
import CalcPrice from './components/SystemDetailScreen/CalcPrice';
import DataConfirmModal from './components/SystemDetailScreen/DataConfirmModal';
import Indicators from './components/SystemDetailScreen/Indicators';
import InfoList from './components/SystemDetailScreen/InfoList';
import SystemComposition from './components/SystemDetailScreen/SystemComposition';

interface ISystemDetailScreenProps {}

const SystemDetailScreen: React.FC<ISystemDetailScreenProps> = observer(() => {
  const { systemsStore, orderStore, goodsStore } = useRootStore();
  const { system } = systemsStore;
  const { area, areaError, setArea } = orderStore;

  const params = useParams<IStringParams>();
  const history = useHistory();
  const [confirmModal, setConfirmModal] = useState(false);
  const [isChangeRoute, setIsChangeRoute] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<null | string>(null);
  const blockChangeRoute = useRef<UnregisterCallback | null>(null);

  const { isXS } = useAllMQ();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  // Effects
  useEffect(() => {
    if (params?.systemId) {
      systemsStore.getSystemById(Number(params.systemId));
    }

    return () => {
      systemsStore.resetSystem();
    };
  }, [params.systemId, systemsStore]);

  useEffect(() => {
    orderStore.setSystemParams({ systemId: Number(params?.systemId), systemPath: history.location.pathname });
    return () => {
      orderStore.setSystemParams(null);
    };
  }, [history.location.pathname, orderStore, params?.systemId]);

  useEffect(() => {
    if (!!area.length || !!systemsStore.area) {
      orderStore.setAreaError(false);
    }

    return () => {
      orderStore.resetOrderCalcValues();
      orderStore.setAreaError(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStore]);

  useEffect(() => {
    blockChangeRoute.current = history.block(location => {
      const isSystemComposition = location.pathname.includes('system-composition');
      const isCartScreen = location.pathname.includes('cart');
      const unblockingOnThesePages = !isSystemComposition && !isCartScreen;

      if (!confirmModal) {
        setConfirmModal(true);
      }

      if (!currentLocation) {
        setCurrentLocation(location.pathname);
      }

      if (!isChangeRoute && area && unblockingOnThesePages) {
        return false;
      }
    });

    return () => {
      if (blockChangeRoute.current) {
        const blockRoute = blockChangeRoute.current;

        blockRoute();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, isChangeRoute, orderStore.isAddedCart, area]);

  useEffect(() => {
    setArea(systemsStore.area);

    if (!!goodsStore.goodsList.length && !!area.length) {
      orderStore.orderCalc(orderStore.orderValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goodsStore.goodsList.length, orderStore, setArea]);

  // Handlers
  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
  };

  const handleDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (system?.documentWebPath) {
      saveAs(system.documentWebPath, system?.title || '');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    systemsStore.setArea(e.target.value);
    setArea(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    orderStore.orderCalc(orderStore.orderValues);
  };

  const handleAddCart = () => {
    window.scrollTo(0, 0);
    orderStore.addCart();
  };

  const handleChangeRoute = () => {
    setIsChangeRoute(true);
    setConfirmModal(false);

    if (blockChangeRoute.current) {
      const unblock = blockChangeRoute.current;

      unblock();
      blockChangeRoute.current = null;
    }

    if (currentLocation) {
      history.push(currentLocation);
    }
  };

  // Renders
  if (systemsStore.loading) {
    return <Loader minHeight="calc(100vh - 112px)" />;
  }

  const renderImg = () => {
    if (system?.imagesWebPath) {
      return <ImagesGallery paths={system.imagesWebPath} />;
    }
  };

  const renderDesc = () => {
    if (system?.description) {
      return (
        <Box mb={3}>
          <Typography variant={isXS ? 'body2' : 'body1'} className={commonClasses.onSurfacePrimary}>
            {system.description}
          </Typography>
        </Box>
      );
    }
  };

  return (
    <Box pt={{ sm: 3 }}>
      <Container maxWidth="lg">
        <div className={classes.topLine}>
          {isXS && (
            <Box mb={2} mx={-2}>
              {renderImg()}
            </Box>
          )}
          <Box display="flex" mb={3} flexDirection={{ xxs: 'column', md: 'row' }}>
            {!isXS && renderImg()}
            <div>
              {system?.title && (
                <Box mb={{ xxs: 2, sm: 3 }}>
                  <Typography
                    variant={isXS ? 'inherit' : 'h5'}
                    className={clsx(commonClasses.onSurfacePrimary, { [typography.headline6Strong]: isXS })}
                  >
                    {system.title}
                  </Typography>
                </Box>
              )}
              <CalcForm
                area={area}
                areaError={areaError}
                loading={orderStore.loading}
                onHandleChange={handleChange}
                onHandleSubmit={handleSubmit}
              />
              <CalcPrice orderCalcInfo={orderStore.orderCalcInfo} basePrice={goodsStore.getBasePrice} />
              <AddCartButtons
                isAddedCart={orderStore.isAddedCart}
                isAddedCartLoading={orderStore.isAddedCartLoading}
                path="/"
                onHandleAddCart={handleAddCart}
              />
            </div>
          </Box>
          {!isXS && renderDesc()}
          {system && <Indicators system={system} />}
          {isXS && (
            <>
              <Box mb={2}>
                <Typography className={clsx(commonClasses.onSurfacePrimary, typography.headline6Strong)}>
                  О системе
                </Typography>
              </Box>
              {renderDesc()}
            </>
          )}
          {system?.info && (
            <Box mb={3}>
              <InfoList data={system.info.split('<br>')} />
            </Box>
          )}
          <Box
            display="flex"
            flexDirection={{ xxs: 'column', sm: 'row' }}
            alignItems={{ xxs: 'stretch', sm: 'center' }}
          >
            {system?.documentWebPath && (
              <Box mr={{ xxs: 0, sm: 2 }} mb={{ xxs: 2, sm: 0 }}>
                <Button
                  fullWidth={isXS}
                  color="secondary"
                  variant="contained"
                  onClick={handleDownload}
                  startIcon={<Icon path={mdiDownloadOutline} size={1} />}
                >
                  Скачать инструкцию по установке
                </Button>
              </Box>
            )}
            {system?.videoUrl && (
              <a className="router-link" href={system?.videoUrl || ''} target="_blank" rel="noreferrer">
                <Button
                  fullWidth={isXS}
                  color="secondary"
                  variant="contained"
                  startIcon={<Icon path={mdiPlayCircleOutline} size={1} />}
                >
                  Видеоинструкция
                </Button>
              </a>
            )}
          </Box>
        </div>
        <div className={classes.centerLine}>
          <SystemComposition />
        </div>
        <Box display="flex" alignItems="center" justifyContent="flex-end" mb={5}>
          <AddCartButtons
            isAddedCart={orderStore.isAddedCart}
            isAddedCartLoading={orderStore.isAddedCartLoading}
            path="/"
            onHandleAddCart={handleAddCart}
            isBottom
          />
        </Box>
        {system?.description2 && (
          <div className={classes.bottomLine}>
            <Typography className={commonClasses.onSurfaceSecondary} variant={isXS ? 'body2' : 'body1'}>
              {system.description2}
            </Typography>
          </div>
        )}

        <DataConfirmModal
          open={confirmModal}
          onHandleChangeRoute={handleChangeRoute}
          onHandleCloseConfirmModal={handleCloseConfirmModal}
        />
      </Container>
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  topLine: {
    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.outline.main}`,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(2.5),
    },
  },
  centerLine: {
    padding: theme.spacing(3, 0),
  },
  bottomLine: {
    position: 'relative',
    borderTop: `1px solid ${theme.palette.outline.main}`,
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5, 0),
    },
  },
}));

export default SystemDetailScreen;

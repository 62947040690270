import { Box, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { ICategory } from 'modules/categories/types/CategoriesTypes';
import { useCommonStyles } from 'styles/commonStyles';
import { useCustomTypography } from 'styles/customTypography';

import { useCardStyles } from './styles';

interface ICardProps extends ICategory {
  path: string;
}

const CategoriesCard: React.FC<ICardProps> = props => {
  const { description, previewWebPath, iconWebPath, title, path } = props;
  const theme = useTheme();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const commonClasses = useCommonStyles();
  const typography = useCustomTypography();

  return (
    <Link className={clsx(classes.link, 'router-link')} to={path}>
      <div className={cardClasses.card}>
        <div className={cardClasses.topLine}>
          <div
            className={cardClasses.img}
            style={{
              background: `linear-gradient(0deg, ${theme.palette.primary.main}, ${theme.palette.primary.main}), url(${previewWebPath}) no-repeat center center/cover`,
            }}
          />
          {iconWebPath && (
            <div className={cardClasses.iconWrap}>
              <div
                className={cardClasses.icon}
                style={{
                  background: `linear-gradient(0deg, ${theme.palette.primary.main}, ${theme.palette.primary.main}), url(${iconWebPath}) no-repeat center center/contain`,
                }}
              />
            </div>
          )}
        </div>
        <Box p={2}>
          <Box display="flex" alignItems="center">
            <Box mr={1} mb={1}>
              <Typography className={clsx(typography.headline6Strong, commonClasses.onSurfacePrimary)}>
                {title}
              </Typography>
            </Box>
          </Box>
          <Typography className={commonClasses.onSurfacePrimary} variant="body1">
            {description}
          </Typography>
        </Box>
      </div>
    </Link>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    transition: transitions.create('transform'),
    '&:hover': {
      transform: 'translateY(-2px)',
    },
  },
}));

export default CategoriesCard;
